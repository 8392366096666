<template>
  <div
    class="checkout"
  >
    <ProgressBarDialog 
      :open="open"
      :percentage-value="currentSubmitionProgress"
    />
    <div class="checkout_header">
      <button
        class="close-btn"
        icon
        @click="$emit('close-drawer')"
      >
        <v-icon color="reddish">
          mdi-close
        </v-icon>
      </button>
      <h2 class="checkout-title">
        {{ $t('checkout.txt.title') }}
      </h2>
    </div>
    <div
      :key="`A-${y}`"
      class="checkout_main"
    >
      <!-- <div
        v-if="loadingCart"
        class="text-center"
      >
        <v-progress-circular
          class="loader"
          :size="30"
          color="peacock"
          indeterminate
        />
      </div> -->
      <v-row
        v-if="loadingCart"
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Loading
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="peacock"
            indeterminate
            rounded
            height="6"
          />
        </v-col>
      </v-row>
      <template v-else>
        <v-expansion-panels
          focusable
          flat
        >
          <v-expansion-panel
            v-for="service in checkoutServices"
            :key="service.id"
            class="service-panel"
            active-class="active"
          >
            <v-expansion-panel-header class="service-panel-header">
              <v-row v-if="service.name">
                <v-col
                  v-if="service"
                  cols="12"
                  sm="6"
                >
                  <v-img
                    v-if="service.iconUrl"
                    :src="service.iconUrl"
                    class="service-img"
                  />
                  <div class="service-info">
                    <div class="service-label">
                      {{ $t('checkout.txt.service') }}
                    </div>
                    <div class="service-name">
                      {{ service.name }}
                    </div>
                  </div>
                </v-col>
                <v-col
                  v-if="service.name"
                  cols="12"
                  sm="6"
                  class="text-sm-right pr-8"
                >
                  <div class="service-resume">
                    <div
                      v-if="service.vehicles.length >0"
                      class="service-vehicles"
                    >
                      {{ service.vehicles.length }} {{ $t('checkout.txt.vehicles_selected') }}
                    </div>
                    <div class="service-totals">
                      <span v-if="service.totalMonthlyService != 0">
                        <b><span v-if="service.totalMonthlyService < 0">-</span> {{ currency.symbol }} {{ Math.abs(service.totalMonthlyService) }}</b> {{ $t('checkout.txt.month') }}
                      </span>
                      <span v-if="service.totalYearlyService != 0 && service.totalMonthlyService != 0"> & </span>
                      <span v-if="service.totalYearlyService != 0">
                        <b><span v-if="service.totalYearlyService < 0">-</span> {{ currency.symbol }} {{ Math.abs(service.totalYearlyService) }}</b> {{ $t('checkout.txt.year') }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <div class="service-info">
                    <div class="remove-title-header">
                      <span v-if="service.id==='scrapped'">{{ $t('checkout.txt.remove_vehicles_scrapped') }}</span>
                      <span v-else>{{ $t('checkout.txt.remove_vehicles_sold') }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="service-panel-content">
              <div v-if="service.name">
                <h2 v-if="service.vehicles.length >0">
                  {{ $t('checkout.txt.result_selected') }} {{ service.vehicles.length }} {{ $t('checkout.txt.result_vehicles') }}
                </h2>
                <div
                  v-if="service.vehicles.length >0"
                  class="options d-sm-none py-3"
                >
                  <span class="sort-by-label mobile">{{ $t('checkout.txt.sort_by') }}: </span>
                  <v-select
                    class="d-inline-flex sort-by-items mobile"
                    background-color="pale-three"
                    color="slate"
                    :append-icon="'mdi-chevron-down'"
                    :items="sortItems"
                    :menu-props="{ offsetY: true }"
                    :placeholder="$t('checkout.txt.period')"
                    dense
                    filled
                    rounded
                    return-object
                    @change="sortByItem"
                  />
                </div>
                <div
                  v-if="service.vehicles.length >0"
                  class="options"
                >
                  <div class="start-spacer" />
                  <!-- <a
                    v-if="allSelected"
                    @click="selectedTrucks = []; allSelected = !allSelected"
                  > {{ $t('checkout.txt.unselect_all') }} </a>
                  <a
                    v-else
                    @click="selectedTrucks=service.vehicles; allSelected = !allSelected"
                  > {{ $t('checkout.txt.select_all') }} </a> -->
                  <div class="vertical-spacer" />
                  <a @click="deleteItem(selectedTrucks, service)">{{ $t('checkout.txt.delete') }} </a>
                  <div class="vertical-spacer" />
                  <div class="horizontal-spacer" />
                  <span class="d-none d-sm-block sort-by-label">{{ $t('checkout.txt.sort_by') }}: </span>
                  <v-select
                    class="d-none d-sm-inline-flex sort-by-items"
                    background-color="pale-three"
                    color="slate"
                    :append-icon="'mdi-chevron-down'"
                    :items="sortItems"
                    :menu-props="{ offsetY: true }"
                    :placeholder="$t('None')"
                    dense
                    filled
                    rounded
                    return-object
                    @change="sortByItem"
                  />
                </div>
              </div>
              <div v-else>
                <div class="confirm-question">
                  {{ $t('checkout.txt.confirm_question') }}
                </div>
              </div>
              <v-data-table
                v-if="service.vehicles.length > 0"
                v-model="selectedTrucks"
                item-key="id"
                :items="service.vehicles"
                :items-per-page="numberItems"
                :sort-by="sortBy"
                :hide-default-footer="true"
                class="service-table"
              >
                <template v-slot:body="{ items }">
                  <div
                    v-for="item in items"
                    :key="item.vin"
                    class="service-table-row"
                    :class="{ 'unsubscribe': !item.addSubscription }"
                  >
                    <v-row>
                      <v-col
                        cols="1"
                        sm="1"
                        class="align-self-center"
                      >
                        <v-checkbox
                          v-model="selectedTrucks"
                          :value="item"
                          hide-details
                          :ripple="false"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        class="align-self-center d-none d-md-inline"
                      >
                        <v-img
                          v-if="item.vehicleType!==null"
                          :src="$t('trucks.img.'+item.vehicleType+'.img')"
                          width="32"
                        />
                      </v-col>
                      <!-- <v-col
                        cols="2"
                        :class="service.name ? 'align-self-center d-none d-md-inline' : 'align-self-center d-none d-md-inline pl-4'"
                      > -->
                      <!-- <div class="table-header">
                          {{ headers[0].text }}
                        </div>
                        <div>
                          {{ item.vehicleType || 'XF' }}
                        </div> -->
                      <!-- </v-col> -->
                      <v-col
                        :cols="service.name ? '5' : '6'"
                        :md="service.name ? '5' : '6'"
                        class="align-self-center d-none d-sm-inline"
                      >
                        <div class="table-header">
                          {{ headers[0].text }}
                        </div>
                        <div v-if="item.vin">
                          <v-tooltip
                            content-class="vehiclevin-tooltip"
                            bottom
                            color="#4a5568"
                          >
                            <template v-slot:activator="{ on }">
                              <span v-on="on">{{ item.vin }}</span>
                            </template>
                            <div>
                              <div>
                                <span class="vehiclevin-span"> {{ $t('vehicles_overview.txt.vehicle_name') }}: </span>{{ item.vehicleName }}
                              </div>
                              <div>
                                <span class="vehiclevin-span">{{ $t('vehicles_overview.txt.vin_number') }}: </span>{{ item.vin }}
                              </div>
                            </div>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col
                        cols="4"
                        :md="service.name ? '3' : '4'"
                        class="align-self-center"
                      >
                        <div class="table-header">
                          Unit Number
                        </div>
                        <div>
                          {{ item.unitNumber }}
                        </div>
                      </v-col>
                      <v-col
                        v-if="service.name"
                        cols="6"
                        md="2"
                        class="align-self-center"
                      >
                        <div class="table-header" />
                        <div
                          class="row-total"
                          :class="{ 'unsubscribe': !item.addSubscription }"
                        >
                          <span v-if="!item.addSubscription">-</span> <span v-if="item.period === 'month'"> {{ $t('checkout.txt.month') }}</span>
                          <span v-if="item.addSubscription"> {{ currency.symbol }} {{ item.cost }} <span v-if="item.period === 'month'"> {{ $t('checkout.txt.month') }}</span></span>
                          <span v-if="item.period === 'year'"> {{ $t('checkout.txt.year') }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <div
                      v-if="!item.addSubscription"
                      class="data-end-message"
                    >
                      {{ $t('checkout.txt.service_end_msg') }} <strong style="color:black;">{{ item.endDate | formatDate }}</strong>
                    </div>
                  </div>
                </template>
              </v-data-table>
              <div
                v-else
                class="service-table-row"
              >
                <h2>{{ service.name }}</h2>
                <div class="service-description">
                  {{ service.description }}
                </div>
              </div>
              <div class="show-all">
                <v-btn
                  v-if="service.vehicles.length > 2 && numberItems < service.vehicles.length"
                  elevation="0"
                  @click="numberItems = service.vehicles.length"
                >
                  {{ $t('checkout.txt.show_all') }} ({{ service.vehicles.length }})
                </v-btn>
                <v-btn
                  v-if="numberItems === service.vehicles.length && service.vehicles.length > 2"
                  elevation="0"
                  @click="numberItems = 2"
                >
                  {{ $t('checkout.txt.show_less_items') }}
                </v-btn>
              </div>
              <v-row v-if="!service.name">
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  class="text-left text-sm-left ml-3 mt-8"
                >
                  <span class="total-label">{{ $t('checkout.txt.date_removal') }}:</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  class="text-center text-sm-center ml-n4 mt-6"
                >
                  <v-select
                    v-model="day"
                    :value="day"
                    class="select mx-0"
                    :items="days"
                    :placeholder="$t('checkout.txt.day')"
                    append-icon="mdi-chevron-down"
                    :menu-props="{ offsetY: true }"
                    background-color="pale-two"
                    filled
                    rounded
                    dense
                    lazy
                    @change="updateVehicleToRemove(service)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  class="text-center text-sm-center ml-n2 mt-6 "
                >
                  <v-select
                    v-model="month"
                    :value="month"
                    class="select mx-0"
                    :items="months"
                    item-text="name"
                    item-value="number"
                    :placeholder="$t('checkout.txt.month')"
                    append-icon="mdi-chevron-down"
                    :menu-props="{ offsetY: true }"
                    background-color="pale-two"
                    filled
                    rounded
                    dense
                    lazy
                    @change="updateVehicleToRemove(service)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  class="text-center text-sm-center ml-n2 mt-6"
                >
                  <v-select
                    v-model="year"
                    :value="year"
                    class="select mx-0"
                    :items="years"
                    :placeholder="$t('checkout.txt.year')"
                    append-icon="mdi-chevron-down"
                    :menu-props="{ offsetY: true }"
                    background-color="pale-two"
                    filled
                    rounded
                    dense
                    lazy
                    @change="updateVehicleToRemove(service)"
                  />
                </v-col>
                <v-col
                  v-if="service.id==='changeOwner'"
                  cols="12"
                  sm="3"
                  md="3"
                  class="text-left text-sm-left ml-3"
                >
                  <span class="total-label">{{ $t('checkout.txt.new_owner') }} {{ changeOwnerTotal }}:</span>
                </v-col>
                <v-col
                  v-if="service.id==='changeOwner'"
                  cols="12"
                  sm="9"
                  md="9"
                  class="text-center text-sm-center ml-n4 pr-7"
                >
                  <v-text-field
                    v-model="newOwner"
                    class="field-change-owner py-0"
                    :placeholder="$t('checkout.txt.new_owner_ph')"
                    maxlength="255"
                    background-color="pale-two"
                    filled
                    rounded
                    dense
                    lazy
                    @change="updateVehicleToRemove(service)"
                  />
                </v-col>
              </v-row>
              <!-- <div v-if="service.id==='changeOwner'">
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  class="text-left text-sm-left ml-3 mt-8"
                >
                {{ service.id }}
                <span class="total-label">{{ $t('checkout.txt.new_owner') }}:</span>
              </v-col>
              </div> -->
              <div class="total">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="align-self-end text-center text-sm-left"
                  >
                    <v-icon
                      color="cloudy"
                      dense
                    >
                      mdi-delete
                    </v-icon>
                    <v-btn
                      v-if="service.name"
                      text
                      small
                      color="dark"
                      class="delete-order"
                      @click="deleteService(service)"
                    >
                      {{ $t('checkout.txt.delete_order') }}
                    </v-btn>
                    <v-btn
                      v-else
                      text
                      small
                      color="dark"
                      class="undo-removal"
                      @click="deleteItem(selectedTrucks, service)"
                    >
                      {{ $t('checkout.txt.undo_removal') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="service.name"
                    cols="12"
                    sm="6"
                    class="text-center text-sm-right"
                  >
                    <span
                      v-if="service.totalMonthlyService != 0 || service.totalYearlyService != 0"
                      class="total-label"
                    >{{ $t('checkout.txt.total') }}:</span>
                    <span
                      v-if="service.totalMonthlyService != 0"
                      class="total-value"
                    >
                      <b><span v-if="service.totalMonthlyService < 0 && service.totalMonthlyService != 0">-</span> {{ currency.symbol }} {{ Math.abs(service.totalMonthlyService) }}</b> <span class="total-value-label">{{ $t('checkout.txt.month') }}</span>
                    </span>
                    <span v-if="service.totalYearlyService != 0 && service.totalMonthlyService != 0"> & </span>
                    <span
                      v-if="service.totalYearlyService != 0"
                      class="total-value"
                    >
                      <b><span v-if="service.totalYearlyService < 0">-</span> {{ currency.symbol }} {{ Math.abs(service.totalYearlyService) }}</b> <span class="total-value-label">{{ $t('checkout.txt.year') }}</span>
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <template>
          <div
            v-if="addSubscriptionCount > 0"
            class="terms mb-5"
          >
            <v-checkbox
              v-model="checkTerms"
              hide-details
              class="d-inline-block check-terms"
              :ripple="false"
              value=""
              @change="onCheckBoxChanged"
            >
              <template v-slot:label>
                <div>
                  I agree to the 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="termsAndConditionURL"
                        @click.stop
                        v-on="on"
                      >
                        Terms and Conditions
                      </a>
                    </template>
                    Paccar agreement
                  </v-tooltip>
                  of subscribing to the 
                  <span 
                    v-for="(service, index) in checkoutServices"
                    :key="service.id"
                  >
                    <strong>{{ service.name }}</strong> 
                    <span v-if="checkoutServices.length > 1 && index == 0">
                      and 
                    </span>
                  </span>
                  Services
                </div>
              </template>
            </v-checkbox>
          </div>
        </template>
      </template>
     
      <v-btn
        v-if="!loadingCart"
        small
        color="red"
        outlined
        :class="{ 'mt-5': addSubscriptionCount === 0}"
        @click="deleteOrder"
      >
        {{ $t('Clear Cart') }}
      </v-btn>
      <!-- <div
        v-if="loadingTotal"
      >
        <v-progress-linear
          indeterminate
        />
      </div> -->
      <v-alert
        v-if="!checkingPaymentMethod && !validPaymentMethod"
        text
        prominent
        color="reddish"
        class="mt-8"
      >
        <label class="alert-cdd-check">{{ $t(' To update subscriptions, please add a valid default payment method.') }}</label>
      </v-alert>
      <!-- <v-alert
        v-if="checkTerms && validPaymentMethod"
        class="mt-8"
      >
        <label
          style="text-align: center;"
        ><v-icon large> mdi-credit-card</v-icon> Payment: <v-icon color="success">mdi-check</v-icon></label>
      </v-alert> -->
      <v-row
        v-if="!loadingCart"
        class="terms mt-10"
      >
        <v-col>
          {{ $t('checkout.txt.annual_charges') }}
        </v-col>
      </v-row>
    </div>
    <v-footer
      v-if="!loadingCart"
      :key="`B-${y}`"
      class="checkout_footer"
      absolute
    >
      <v-row>
        <!-- <v-col
          cols="12"
        >
          <v-progress-linear
            v-if="loadingTotal"
            indeterminate
          />
        </v-col> -->
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <div
              class="total-price"
            >
              {{ $t('Sub Total') }}
            </div>
            <!-- <span v-if="totalMonthly < 0">
              <b><span v-if="totalMonthly < 0">-</span> {{ currency.symbol }} {{ Math.abs(totalMonthly) }}</b> {{ $t('checkout.txt.month') }}
            </span> -->
            <!-- <span v-if="totalYearly < 0 && totalMonthly != 0"> & </span> -->
            <span v-if="!loadingTotal">
              <b style="font-size:25px;"> {{ currency.symbol }} {{ new Intl.NumberFormat(this.$i18n.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(amountWithoutTax) }}</b> {{ $t('checkout.txt.year') }}
            </span>
            <span v-else>
              <v-progress-linear
                indeterminate
              />
            </span>
          </div>
          <div />
        </v-col>
        <v-divider
          vertical
        />
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <div
              class="total-price"
            >
              {{ $t('Sales Tax') }}
            </div>
            <!-- <span v-if="totalMonthly != 0">
              <b><span v-if="totalMonthly < 0">-</span> {{ currency.symbol }} {{ Math.abs(totalMonthly) }}</b> {{ $t('checkout.txt.month') }}
            </span>
            <span v-if="totalYearly != 0 && totalMonthly != 0"> & </span> -->
            <span v-if="!loadingTotal">
              <b style="font-size:25px;"> {{ currency.symbol }} {{ new Intl.NumberFormat(this.$i18n.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(taxAmount) }}</b>
            </span>
            <span v-else>
              <v-progress-linear
                indeterminate
              />
            </span>
          </div>
          <div />
        </v-col>
        <v-divider
          vertical
        />
        <v-col
          cols="12"
          md="3"
          class="text-right text-md-left"
        >
          <div>
            <div
              class="total-price"
            >
              {{ $t('Total') }}
            </div>
            <!-- <span v-if="totalMonthly < 0">
              <b><span v-if="totalMonthly < 0">-</span> {{ currency.symbol }} {{ Math.abs(totalMonthly) }}</b> {{ $t('checkout.txt.month') }}
            </span>
            <span v-if="totalYearly < 0 && totalMonthly != 0"> & </span>-->
            <span v-if="!loadingTotal">
              <b style="font-size:25px;"> {{ currency.symbol }} {{ new Intl.NumberFormat(this.$i18n.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(amountWithTax) }}</b> {{ $t('checkout.txt.year') }}
            </span>
            <span v-else>
              <v-progress-linear
                indeterminate
              />
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="align-self-center"
        >
          <v-btn
            large
            dark
            right
            color="peacock"
            elevation="0"
            class="float-right btn-pay"
            :disabled="addSubscriptionCount > 0 && (!checkTerms || !validPaymentMethod)"
            :loading="loading || loadingTotal"
            @click="goToPayment (open = true)"
          >
            {{ $t('checkout.txt.confirm_button') }}
          </v-btn>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red lighten-2"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Click Me
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
import basketService from '@/services/basketService'
import { roleRatePlanService } from '@/services/roleRatePlanService'
import ProgressBarDialog from './ProgressBarDialog.vue'

export default {
  name: 'AppCheckout',
  components: { ProgressBarDialog },
  data() {
    return {
      checkbox: false,
      changeOwnerTotal: 0,
      amountWithTax: 0,
      taxAmount: 0,
      amountWithoutTax: 0,
      ratePlanId: '',
      subscriptionsToPreview: [],
      ratePlansForPreview: [],
      services: [],
      sortItems: [],
      itemIndex: 0,
      lessTrucks: [],
      selectedTrucks: [],
      headers: [],
      // checkout: [],
      checkoutTrucks: [],
      checkoutServices: [],
      checkTerms: false,
      checkAllVehicles: [],
      numberItems: 10,
      sortBy: '',
      y: 0,
      totaltruck: 0,
      isAddSubscription: true,
      allSelected: false,
      notification: false,
      notificationText: '',
      notificationType: 'success',
      singleSelect: false,
      newOwner: '',
      day: '',
      month: '',
      year: '',
      monthsWith30Days: ['04', '06', '09', '11'],
      months: [
        {
          name: 'January',
          number: '01'
        },
        {
          name: 'February',
          number: '02'
        },
        {
          name: 'March',
          number: '03'
        },
        {
          name: 'April',
          number: '04'
        },
        {
          name: 'May',
          number: '05'
        },
        {
          name: 'June',
          number: '06'
        },
        {
          name: 'July',
          number: '07'
        },
        {
          name: 'August',
          number: '08'
        },
        {
          name: 'September',
          number: '09'
        },
        {
          name: 'October',
          number: '10'
        },
        {
          name: 'November',
          number: '11'
        },
        {
          name: 'December',
          number: '12'
        }
      ],
      loading: false,
      loadingCart: false,
      loadingTotal: false,
      authorized: localStorage.getItem('authorized'),
      vehicles: [],
      validPaymentMethod: false,
      checkingPaymentMethod: true,
      addSubscriptionCount: 0,
      termsAndConditionURL: '',
      currentSubmitionProgress: 0,
      open: false
    }
  },
  computed: {
    ...mapState('checkout', ['totalCheckout', 'checkout']),
    ...mapState('account', ['currency', 'accountInformation']),
    ...mapState('trucks', ['trucks']),
    ...mapState('notification', ['snackbar']),
    openProgressBarModal() {
      return (open = true)
    },
    totalMonthly() {
      const totalMonthly = this.checkoutServices.reduce(
        (prev, cur) => prev + parseFloat(cur.totalMonthlyService),
        0
      )
      return totalMonthly || 0
    },
    totalYearly() {
      const totalYearly = this.checkoutServices.reduce(
        (prev, cur) => prev + parseFloat(cur.totalYearlyService),
        0
      )
      return totalYearly || 0
    },
    years: function() {
      const year = []
      const currentYear = new Date().getFullYear()
      for (let i = 1960; i <= currentYear; ++i) {
        year.push({ text: i, value: i.toString() })
      }
      return year
    },
    days: function() {
      const day = []
      let lastday
      let d
      if (this.monthsWith30Days.includes(this.month)) {
        lastday = 30
      } else if (this.leapYear(this.year)) {
        lastday = 29
      }
      if (this.month === '02' && !this.leapYear(this.year)) {
        lastday = 28
      } else {
        lastday = 31
      }
      for (let i = 1; i <= lastday; ++i) {
        if (i <= 9) d = '0' + i
        else d = i
        day.push({ text: d, value: d.toString() })
      }
      return day
    }
  },
  async created() {
    this.loadingCart = true
    this.loadingTotal = true
    await this.getSortItems()
    await this.getHeaders()
    await this.getServices()
    await this.getVehicles()
    await this.getCheckoutAc()
    await this.getTermAndConditionPrismic()
  },
  methods: {
    ...mapMutations('checkout', [
      'setTotalCheckout',
      'removeFromTotalCheckout',
      'decrement',
      'setCheckout'
    ]),
    ...mapMutations('trucks', ['setBillingPeriod']),
    ...mapActions('trucks', ['getVehicles']),
    ...mapActions('notification', ['setSnackbar']),
    ...mapActions('checkout', ['getCheckout']),
    getVehicles() {
      return apiCalls
        .get(api.TrucksPerServiceDetail, '', `?pageIndex=1&productId=`)
        .then(res => {
          if (res.status == 200) {
            this.vehicles = res.data
          } else {
            this.vehicles = []
          }
        })
    },
    leapYear: function(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
    },
    getServices() {
      return apiCalls
        .get(api.ProductsGetAll, '', '?checkForActivatedVehicles=false')
        .then(res => (this.services = res.data))
        .then(() => {
          this.services.forEach(serv => {
            serv.productRatePlans
              .filter(
                f =>
                  f.ratePlanType__c ==
                  roleRatePlanService.userRatePlan(localStorage.getItem('role'))
              )
              .forEach(product => {
                product.productRatePlanCharges.forEach(bp => {
                  if (bp.billingPeriod === 'Annual') {
                    serv.ratePlanIdYearly = product.id
                    serv.ratePlanChargesIdYearly = bp.id
                    bp.pricing.forEach(pr => {
                      if (pr.currency === this.currency.code)
                        serv.yearlyCost = pr.price
                    })
                    // } else if (bp.billingPeriod === 'Month') {
                    //   serv.ratePlanIdMonthly = product.id
                    //   serv.ratePlanChargesIdMonthly = bp.id
                    //   bp.pricing.forEach(pr => {
                    //     if (pr.currency === this.currency.code)
                    //       serv.monthlyCost = pr.price
                    //   })
                  }
                })
              })
            return serv
          })
        })
      // .then(() => {
      //   return apiCalls
      //     .getCMSContent('resources', 'catalog')
      //     .then(({ results }) => {
      //       this.services.forEach(serv => {
      //         results[0].data.options.forEach(cms => {
      //           if (cms.externalkey[0].text === serv.sku) {
      //             serv.name = cms.title[0].text
      //             serv.iconUrl = cms.iconactive.url
      //             serv.description = cms.description[0].text
      //             serv.productRatePlans.forEach(product => {
      //               product.productRatePlanCharges.forEach(bp => {
      //                 if (bp.billingPeriod === 'Annual') {
      //                   serv.ratePlanIdYearly = product.id
      //                   serv.ratePlanChargesIdYearly = bp.id
      //                   bp.pricing.forEach(pr => {
      //                     if (pr.currency === this.currency.code)
      //                       serv.yearlyCost = pr.price
      //                   })
      //                 } else if (bp.billingPeriod === 'Month') {
      //                   serv.ratePlanIdMonthly = product.id
      //                   serv.ratePlanChargesIdMonthly = bp.id
      //                   bp.pricing.forEach(pr => {
      //                     if (pr.currency === this.currency.code)
      //                       serv.monthlyCost = pr.price
      //                   })
      //                 }
      //               })
      //             })
      //           }
      //         })
      //         return serv
      //       })
      //     })
      // })
    },
    getEndDate(truckSubscriptionID, productRatePlanChargeID) {
      let chargedThroughDate = ''
      // * Selects the truck based on the Subscription ID/Number
      const truck = this.vehicles.find(truck => {
        return truck.subscriptionNumber == truckSubscriptionID
      })
      const subEndDate = truck.subscriptionEndDate
      return subEndDate
      // * Searches the selected truck rateplans for the correct productRatePlanChargeID
      truck.ratePlans.forEach(rateplan => {
        rateplan.charges.forEach(rateplancharge => {
          if (
            rateplancharge.productRatePlanChargeId == productRatePlanChargeID
          ) {
            // * When the correct ratePlanCharge is found, gets the chargedThroughDate and effectiveEndDate
            chargedThroughDate = rateplancharge.chargedThroughDate
          }
        })
      })
      let endDate = ''
      // * If there isn't a chargedThroughDate gets today and sets the chargedThroughDate to be the 1st day of the next valid month (must have at least 30 days between today and chargedThroughDate).
      if (!chargedThroughDate) {
        chargedThroughDate = moment().format('YYYY-MM-DD')
        const day = moment(chargedThroughDate).date()
        const endYear = moment(chargedThroughDate).year()
        const currentYear = moment().year()
        if (day == 1)
          endDate = moment(chargedThroughDate)
            .add(1, 'month')
            .subtract(endYear - currentYear, 'years')
            .format('YYYY-MM-01')
        else
          endDate = moment(chargedThroughDate)
            .add(2, 'month')
            .subtract(endYear - currentYear, 'years')
            .format('YYYY-MM-01')
        // }
      }
      // * If there is a chargedThroughDate, it'll be correct from BE, so just returns it.
      endDate = chargedThroughDate
      return endDate
    },
    getCheckoutAc() {
      let totalMonthly = 0
      let totalYearly = 0
      this.addSubscriptionCount = 0
      this.checkoutServices = []
      this.subscriptionsToPreview = []
      // apiCalls.get(api.CartGetAll, '', '')
      // this.getCheckout().then(res => {
      // this.checkout = res.data.products
      const x = []
      if (this.checkout.products) {
        this.checkout.products.forEach(i => {
          this.services.forEach(service => {
            let auxService = {}
            const auxVehicles = []
            if (i.productID == service.id) {
              auxService = service
              let sumMonthly = 0
              let sumYearly = 0
              this.totaltruck += i.rateplans.length
              i.rateplans.forEach(rateplan => {
                rateplan.subscriptions.forEach(truck => {
                  this.subscriptionsToPreview.push({
                    id: rateplan.productRateplanID,
                    add: truck.addSubscription,
                    subscriptionId: truck.subscriptionID
                  })
                  if (truck.addSubscription) {
                    this.isAddSubscription = true
                    ++this.addSubscriptionCount
                  } else {
                    this.isAddSubscription = false
                  }
                  if (truck.subscriptionID) {
                    const vehicle = Object.assign(
                      {},
                      this.vehicles.find(
                        vhc => vhc.subscriptionNumber == truck.subscriptionID
                      )
                    )
                    if (
                      truck.productRateplanChargeID ===
                        service.ratePlanChargesIdYearly ||
                      true
                    ) {
                      // console.log('RateChargesId', this.ratePlansForPreview)
                      // apiCalls
                      //   .get(api.TrucksGet, truck.subscriptionID, '')
                      //   .then(res => {
                      vehicle.cost = service.yearlyCost || 0
                      vehicle.period = 'year'
                      if (truck.addSubscription) {
                        sumYearly += vehicle.cost
                        totalYearly += vehicle.cost
                      } else {
                        // sumYearly -= vehicle.cost
                        // totalYearly -= vehicle.cost
                        vehicle.endDate = this.getEndDate(
                          truck.subscriptionID,
                          service.ratePlanChargesIdYearly,
                          vehicle.period
                        )
                      }
                      vehicle.addSubscription = truck.addSubscription
                      auxVehicles.push(vehicle)
                    } else if (
                      truck.productRateplanChargeID ===
                      service.ratePlanChargesIdMonthly
                    ) {
                      res.data.cost = service.monthlyCost || 0
                      res.data.period = 'month'
                      if (truck.addSubscription) {
                        sumMonthly += res.data.cost
                        totalMonthly += res.data.cost
                      } else {
                        sumMonthly -= res.data.cost
                        totalMonthly -= res.data.cost
                        res.data.endDate = this.getEndDate(
                          truck.subscriptionID,
                          service.ratePlanChargesIdMonthly,
                          res.data.period
                        )
                      }
                      res.data.addSubscription = truck.addSubscription
                      auxVehicles.push(res.data)
                    }
                    auxService.totalMonthlyService = sumMonthly
                    auxService.totalYearlyService = sumYearly
                    this.y += 1
                    x.totalMonthly = totalMonthly
                    x.totalYearly = totalYearly
                    // })
                  } else {
                    if (truck.addSubscription) {
                      if (
                        truck.productRateplanChargeID ===
                        service.ratePlanChargesIdYearly
                      ) {
                        auxService.cost = service.yearlyCost || 0
                        auxService.period = 'year'
                        sumYearly += service.yearlyCost
                        totalYearly += service.yearlyCost
                      } else if (
                        truck.productRateplanChargeID ===
                        service.ratePlanChargesIdMonthly
                      ) {
                        auxService.cost = service.monthlyCost || 0
                        auxService.period = 'month'
                        sumMonthly += service.monthlyCost
                        totalMonthly += service.monthlyCost
                      }
                    } else if (
                      rateplan.productRateplanID === service.ratePlanIdYearly
                    ) {
                      sumYearly += service.yearlyCost
                      totalYearly += service.yearlyCost
                    } else {
                      sumMonthly -= service.monthlyCost
                      totalMonthly -= service.monthlyCost
                    }
                    auxService.totalMonthlyService = sumMonthly || 0
                    auxService.totalYearlyService = sumYearly || 0
                    this.y += 1
                    x.totalMonthly = totalMonthly || 0
                    x.totalYearly = totalYearly || 0
                  }
                  auxService.vehicles = auxVehicles
                })
              })
              // for (let i = 0; i < auxService.vehicles.length; i++) {
              //   console.log('id', this.ratePlanId)
              //   this.ratePlansForPreview.push({
              //     id: this.ratePlanId.id
              //   })
              // }
              // auxService.vehicles.forEach(x =>
              //   this.ratePlansForPreview.push(x.ratePlanChargesIdYearly)
              // )
              this.amountWithTax = 0
              apiCalls
                .post(
                  api.SubscriptionPreview,
                  '',
                  '',
                  this.subscriptionsToPreview
                )
                .then(res => {
                  this.taxAmount = res.data[0].taxAmount
                  this.amountWithTax = res.data[0].amount
                  this.amountWithoutTax = res.data[0].amountWithoutTax
                  this.loadingCart = false
                  this.loadingTotal = false
                  x.push(auxService)
                })
                .catch(() => {
                  this.$emit('close-drawer'),
                    this.setSnackbar({
                      showing: true,
                      type: 'error',
                      title: 'Error',
                      timeout: 6000,
                      position: 'top',
                      right: 'right',
                      icon: 'mdi-alert-circle',
                      text:
                        'Some services appears to be down. Please try again later'
                    })
                })
            }
          })
          if (i.productID === 'changeOwner' || i.productID === 'scrapped') {
            const auxService = {}
            const auxVehicles = []
            this.totaltruck += i.rateplans.length
            i.rateplans.forEach(rateplan => {
              rateplan.subscriptions.forEach(truck => {
                if (truck.subscriptionID) {
                  apiCalls
                    .get(api.TrucksGet, truck.subscriptionID, '')
                    .then(res => {
                      res.data.addSubscription = 'remove'
                      this.y += 1
                      auxVehicles.push(res.data)
                      auxService.totalYearlyService += this.vehicleCostRatePlans(
                        res.data
                      )
                    })
                }
                auxService.id = i.productID
                auxService.vehicles = auxVehicles
                auxService.totalMonthlyService = 0
                auxService.totalYearlyService = 0
              })
            })
            if (i.metaData) {
              const metaData = JSON.parse(i.metaData)
              let cancelationDate = new Date()
              cancelationDate = metaData.cancelationDate
              this.cancelationDate = cancelationDate
              this.newOwner = metaData.newOwner
              const arrayDate = cancelationDate.split('-')
              this.year = arrayDate[0]
              this.month = arrayDate[1]
              this.day = arrayDate[2]
            }
            x.push(auxService)
          }
        })
      }
      this.checkoutServices = x
      // })
      // console.log(
      //   'services found',
      //   this.checkoutServices,
      //   '::totalTruck:',
      //   this.totaltruck,
      //   '::y:',
      //   this.y
      // )
      return this.checkoutServices
    },
    sortByItem(sortOption) {
      this.sortBy = sortOption.key
    },
    updateTotal() {
      let totalMonthly = 0
      let totalYearly = 0
      this.addSubscriptionCount = 0
      this.subscriptionsToPreview = []
      const x = []

      if (this.checkout.products) {
        this.checkout.products.forEach(i => {
          this.services.forEach(service => {
            let auxService = {}
            const auxVehicles = []
            if (i.productID == service.id) {
              auxService = service
              let sumMonthly = 0
              let sumYearly = 0
              this.totaltruck += i.rateplans.length
              i.rateplans.forEach(rateplan => {
                rateplan.subscriptions.forEach(truck => {
                  this.subscriptionsToPreview.push({
                    id: rateplan.productRateplanID,
                    add: truck.addSubscription,
                    subscriptionId: truck.subscriptionID
                  })
                  if (truck.addSubscription) {
                    this.isAddSubscription = true
                    ++this.addSubscriptionCount
                  } else {
                    this.isAddSubscription = false
                  }
                  if (truck.subscriptionID) {
                    const vehicle = Object.assign(
                      {},
                      this.vehicles.find(
                        vhc => vhc.subscriptionNumber == truck.subscriptionID
                      )
                    )
                    if (
                      truck.productRateplanChargeID ===
                        service.ratePlanChargesIdYearly ||
                      true
                    ) {
                      // console.log('RateChargesId', this.ratePlansForPreview)
                      // apiCalls
                      //   .get(api.TrucksGet, truck.subscriptionID, '')
                      //   .then(res => {
                      vehicle.cost = service.yearlyCost || 0
                      vehicle.period = 'year'
                      if (truck.addSubscription) {
                        sumYearly += vehicle.cost
                        totalYearly += vehicle.cost
                      } else {
                        // sumYearly -= vehicle.cost
                        // totalYearly -= vehicle.cost
                        vehicle.endDate = this.getEndDate(
                          truck.subscriptionID,
                          service.ratePlanChargesIdYearly,
                          vehicle.period
                        )
                      }
                      vehicle.addSubscription = truck.addSubscription
                      auxVehicles.push(vehicle)
                    } else if (
                      truck.productRateplanChargeID ===
                      service.ratePlanChargesIdMonthly
                    ) {
                      res.data.cost = service.monthlyCost || 0
                      res.data.period = 'month'
                      if (truck.addSubscription) {
                        sumMonthly += res.data.cost
                        totalMonthly += res.data.cost
                      } else {
                        sumMonthly -= res.data.cost
                        totalMonthly -= res.data.cost
                        res.data.endDate = this.getEndDate(
                          truck.subscriptionID,
                          service.ratePlanChargesIdMonthly,
                          res.data.period
                        )
                      }
                      res.data.addSubscription = truck.addSubscription
                      auxVehicles.push(res.data)
                    }
                    auxService.totalMonthlyService = sumMonthly
                    auxService.totalYearlyService = sumYearly
                    this.y += 1
                    x.totalMonthly = totalMonthly
                    x.totalYearly = totalYearly
                    // })
                  } else {
                    if (truck.addSubscription) {
                      if (
                        truck.productRateplanChargeID ===
                        service.ratePlanChargesIdYearly
                      ) {
                        auxService.cost = service.yearlyCost || 0
                        auxService.period = 'year'
                        sumYearly += service.yearlyCost
                        totalYearly += service.yearlyCost
                      } else if (
                        truck.productRateplanChargeID ===
                        service.ratePlanChargesIdMonthly
                      ) {
                        auxService.cost = service.monthlyCost || 0
                        auxService.period = 'month'
                        sumMonthly += service.monthlyCost
                        totalMonthly += service.monthlyCost
                      }
                    } else if (
                      rateplan.productRateplanID === service.ratePlanIdYearly
                    ) {
                      sumYearly += service.yearlyCost
                      totalYearly += service.yearlyCost
                    } else {
                      sumMonthly -= service.monthlyCost
                      totalMonthly -= service.monthlyCost
                    }
                    auxService.totalMonthlyService = sumMonthly || 0
                    auxService.totalYearlyService = sumYearly || 0
                    this.y += 1
                    x.totalMonthly = totalMonthly || 0
                    x.totalYearly = totalYearly || 0
                  }
                  auxService.vehicles = auxVehicles
                })
              })

              this.amountWithTax = 0
              this.loadingTotal = true
              apiCalls
                .post(
                  api.SubscriptionPreview,
                  '',
                  '',
                  this.subscriptionsToPreview
                )
                .then(res => {
                  this.taxAmount = res.data[0].taxAmount
                  this.amountWithTax = res.data[0].amount
                  this.amountWithoutTax = res.data[0].amountWithoutTax
                  this.loadingCart = false
                  this.loadingTotal = false
                  x.push(auxService)
                })
                .catch(() => {
                  this.$emit('close-drawer'),
                    this.setSnackbar({
                      showing: true,
                      type: 'error',
                      title: 'Error',
                      timeout: 6000,
                      position: 'top',
                      right: 'right',
                      icon: 'mdi-alert-circle',
                      text:
                        'Some services appears to be down. Please try again later'
                    })
                })
            }
          })
          if (i.productID === 'changeOwner' || i.productID === 'scrapped') {
            const auxService = {}
            const auxVehicles = []
            this.totaltruck += i.rateplans.length
            i.rateplans.forEach(rateplan => {
              rateplan.subscriptions.forEach(truck => {
                if (truck.subscriptionID) {
                  apiCalls
                    .get(api.TrucksGet, truck.subscriptionID, '')
                    .then(res => {
                      res.data.addSubscription = 'remove'
                      this.y += 1
                      auxVehicles.push(res.data)
                      auxService.totalYearlyService += this.vehicleCostRatePlans(
                        res.data
                      )
                    })
                }
                auxService.id = i.productID
                auxService.vehicles = auxVehicles
                auxService.totalMonthlyService = 0
                auxService.totalYearlyService = 0
              })
            })
            if (i.metaData) {
              const metaData = JSON.parse(i.metaData)
              let cancelationDate = new Date()
              cancelationDate = metaData.cancelationDate
              this.cancelationDate = cancelationDate
              this.newOwner = metaData.newOwner
              const arrayDate = cancelationDate.split('-')
              this.year = arrayDate[0]
              this.month = arrayDate[1]
              this.day = arrayDate[2]
            }
          }
        })
      }
    },

    deleteItem(vehiclesSelected, service) {
      const array = []
      vehiclesSelected.forEach(vehicle => {
        this.checkout.products.forEach(product => {
          if (product.productID === service.id) {
            product.rateplans.forEach(rateplan => {
              rateplan.subscriptions.forEach(subscription => {
                if (
                  vehicle.subscriptionNumber === subscription.subscriptionID
                ) {
                  if (subscription.addSubscription == true)
                    array.push({
                      subscriptionID: subscription.subscriptionID,
                      productRateplanID: rateplan.productRateplanID,
                      rateplanID: rateplan.rateplanID,
                      productRateplanChargeID:
                        subscription.productRateplanChargeID,
                      productID: product.productID,
                      addSubscription: 1,
                      metaData: { '': '' }
                    })
                  else if (
                    (product.productID === 'scrapped' ||
                      product.productID === 'changeOwner') &&
                    subscription.addSubscription == false
                  ) {
                    array.push({
                      subscriptionID: subscription.subscriptionID,
                      productRateplanID: rateplan.productRateplanID,
                      rateplanID: rateplan.rateplanID,
                      productRateplanChargeID:
                        subscription.productRateplanChargeID,
                      productID: product.productID,
                      addSubscription: false
                    })
                  } else
                    array.push({
                      subscriptionID: subscription.subscriptionID,
                      productRateplanID: rateplan.productRateplanID,
                      rateplanID: rateplan.rateplanID,
                      productRateplanChargeID:
                        subscription.productRateplanChargeID,
                      productID: product.productID,
                      addSubscription: 0,
                      metaData: { '': '' }
                    })
                }
              })
            })
          }
        })
      })
      array.forEach(item => {
        // return apiCalls.delete(api.CartRemove, '', '', item).then(
        basketService.removeItem(item).then(
          response => {
            if (response.status == 200) {
              this.amountWithTax = 0
              this.taxAmount = 0
              this.amountWithoutTax = 0
              this.$forceUpdate()
              this.fieldKey = Math.random()
              // this.getCheckout()
              this.decrement()
              // this.setCheckout(response.data.products)
              // this.$emit('close-drawer')
              // this.getCheckoutAc()

              vehiclesSelected.forEach(x => {
                const index = service.vehicles.findIndex(ob => ob.vin == x.vin)
                if (index !== -1) {
                  service.vehicles.splice(index, 1)
                  service.vehicles = service.vehicles
                }
              })

              this.updateTotal()

              this.setSnackbar({
                showing: true,
                type: 'success',
                position: 'top',
                right: 'right',
                icon: 'mdi-check-circle',
                text: this.$t('checkout.txt.notification_delete_item')
              })
            } else if (response.status == 204) {
              this.amountWithTax = 0
              this.taxAmount = 0
              this.amountWithoutTax = 0
              this.$forceUpdate()
              this.fieldKey = Math.random()
              this.decrement()
              // this.setCheckout(response.data.products)
              this.$emit('close-drawer')
              this.setSnackbar({
                showing: true,
                type: 'success',
                text: this.$t('checkout.txt.notification_delete_item')
              })
            }
          },
          error => {
            this.setSnackbar({
              showing: true,
              type: 'error',
              text: this.$t('checkout.txt.notification_error')
            })
            return Promise.reject(error)
          }
        )
      })
    },
    async deleteService(serviceId) {
      this.amountWithTax = 0
      this.taxAmount = 0
      this.amountWithoutTax = 0
      return basketService.clearService(serviceId).then(response => {
        if (response.status == 200) {
          this.decrement()
          this.getCheckoutAc()
        }
        if (response.status == 204) {
          this.setTotalCheckout(0)
          this.$emit('close-drawer')
          this.setSnackbar({
            showing: true,
            type: 'success',
            position: 'top',
            right: 'right',
            icon: 'mdi-check-circle',
            text: this.$t('checkout.txt.notification_delete_item')
          })
        }
      })
    },
    deleteOrder() {
      const totalCheckout = 0
      // return apiCalls
      //   .post(api.CartClear, '', '', '')
      return basketService.clearBasket().then(response => {
        if (response.status == 200) {
          this.$forceUpdate()
          // this.getCheckout()
          localStorage.setItem('billingPeriod', null)
          this.setTotalCheckout(totalCheckout)
          this.$emit('close-drawer')
          this.setSnackbar({
            showing: true,
            type: 'success',
            title: 'Success',
            position: 'top',
            right: 'right',
            icon: 'mdi-check-circle',
            text: this.$t('checkout.txt.notification_delete_order')
          })
        } else {
          this.setSnackbar({
            showing: true,
            type: 'error',
            text: this.$t('checkout.txt.notification_error')
          })
          return Promise.reject(error)
        }
      })
      // .then(
      //   () => {
      //     return apiCalls.get(api.CartGetAll, '', '').then(res => {
      //       if (res.data.products) {
      //         res.data.products.forEach(i => {
      //           if (i.rateplans) totalCheckout += i.rateplans.length
      //           else totalCheckout == 0
      //         })
      //       } else totalCheckout == 0
      //       this.setTotalCheckout(totalCheckout)
      //       this.setCheckout(res.data.products)
      //       this.$emit('close-drawer')
      //       this.setSnackbar({
      //         showing: true,
      //         type: 'success',
      //         text: this.$t('checkout.txt.notification_delete_order')
      //       })
      //     })
      //   },
      //   error => {
      //     this.setSnackbar({
      //       showing: true,
      //       type: 'error',
      //       text: this.$t('checkout.txt.notification_error')
      //     })
      //     return Promise.reject(error)
      //   }
      // )
    },
    callSetTimeOutProgressBar() {
      apiCalls.get(api.CheckoutProgress, '', '').then(response => {
        this.currentSubmitionProgress = response.data || 0
        if (this.currentSubmitionProgress !== 100) {
          setTimeout(this.callSetTimeOutProgressBar, 500)
        }
      })
    },

    goToPayment() {
      const checkoutArray = []
      basketService.loadCheckout().then(result => {
        result.products.forEach(x => {
          x.rateplans.forEach(y => {
            y.subscriptions.forEach(sub => {
              checkoutArray.push({
                type:
                  sub.addSubscription == true ? 'AddProduct' : 'RemoveProduct',
                ratePlanId: sub.ratePlanID,
                productRatePlanId: sub.productRateplanID,
                subscriptionNumber: sub.subscriptionID,
                productId: x.productID
              })
            })
          })
        })
      })
      this.loading = true

      this.callSetTimeOutProgressBar()

      return apiCalls
        .post(api.CartSubmit, '', '', { subscriptionsCheckout: checkoutArray })
        .then(response => {
          if (response.status == 200) {
            this.currentSubmitionProgress = 100
            this.loading = false
            this.$forceUpdate()
            this.getCheckout()
            this.setTotalCheckout(0)
            this.setCheckout([])
            localStorage.setItem('billingPeriod', null)
            this.setBillingPeriod([])
            // this.$emit('close-drawer')
            basketService.clearBasket()
            if (response.data.products && response.data.products.length > 0) {
              this.setSnackbar({
                showing: true,
                type: 'error',
                timeout: 0,
                title: 'Error',
                icon: 'mdi-alert-circle',
                text: this.$t('checkout.txt.notification_error')
              })
            } else {
              this.setSnackbar({
                showing: true,
                type: 'success',
                title: 'Success',
                position: 'top',
                right: 'right',
                icon: 'mdi-check-circle',
                timeout: 2500,
                text: this.$t('checkout.txt.notification_submit_success')
              })
            }
            setTimeout(() => {
              location.reload()
            }, 3000)
          }
        })
        .catch(error => {
          // clearTimeout(timeOut)
          this.loading = false
          this.setSnackbar({
            showing: true,
            type: 'error',
            title: 'Error',
            callback: () => {
              location.reload()
            },
            timeout: 0,
            position: 'top',
            right: 'right',
            icon: 'mdi-alert-circle',
            text: this.parseErrorResponse(error.response.data.error)
          })
          const errorMessage = JSON.parse(error.response.data.error)
          const failedSubs = errorMessage.subscriptions.split(', ')
          checkoutArray.forEach(ca => {
            if (!failedSubs.includes(ca.subscriptionNumber)) {
              const subIndex = checkoutArray.findIndex(
                obj => obj.subscriptionNumber == ca.subscriptionNumber
              )
              if (subIndex !== -1) {
                checkoutArray.splice(subIndex, 1)
              }
            }
          })
          basketService.handleFailedSubs(failedSubs)
        })
    },
    parseErrorResponse(error) {
      const data = JSON.parse(error)
      const failedVins = data.subscriptionsDetails.map(x => x).join(', ')
      return `Subscription(s) failed:<br> ${failedVins}.<br> ${data.ErrorMessage}`
    },
    getSortItems() {
      this.sortItems.push(
        { key: 'unitNumber', text: this.$t('Unit Number') },
        { key: 'vin', text: this.$t('checkout.txt.vin_number') }
      )
    },
    getHeaders() {
      this.headers.push(
        { key: 'vin', text: this.$t('checkout.txt.vin_number') },
        { key: 'unitNumber', text: this.$t('checkout.txt.license_plate') }
      )
    },
    updateVehicleToRemove(service) {
      this.cancelationDate = this.year + '-' + this.month + '-' + this.day
      this.checkout.forEach(product => {
        if (
          service.id === product.productID &&
          product.productID === 'scrapped'
        ) {
          product.rateplans.forEach(rateplan => {
            rateplan.subscriptions.forEach(subscription => {
              return apiCalls.put(api.CartUpdate, '', '', {
                subscriptionID: subscription.subscriptionID,
                productID: product.productID,
                metaData: { cancelationDate: this.cancelationDate }
              })
            })
          })
        } else if (
          service.id === product.productID &&
          product.productID === 'changeOwner'
        ) {
          product.rateplans.forEach(rateplan => {
            rateplan.subscriptions.forEach(subscription => {
              return apiCalls.put(api.CartUpdate, '', '', {
                subscriptionID: subscription.subscriptionID,
                productID: product.productID,
                metaData: {
                  cancelationDate: this.cancelationDate,
                  newOwner: this.newOwner
                }
              })
            })
          })
        }
      })
    },
    vehicleCostRatePlans(vehicleSub) {
      let total = 0
      vehicleSub.ratePlans.forEach(res => {
        res.charges.forEach(x => (total += x.price))
      })
      this.changeOwnerTotal = total
      return total
    },
    onCheckBoxChanged(event) {
      if (event) {
        return apiCalls
          .get(api.ConfirmDefaultPaymentMethod, '', '')
          .then(res => {
            this.validPaymentMethod = res.data
            this.checkingPaymentMethod = false
          })
      }
    },
    getTermAndConditionPrismic() {
      return apiCalls
        .getCMSContent('documents', 'documents')
        .then(({ results }) => {
          const truckconnectivity = results.find(
            x => x.data.truck_connectivity_pdf
          )
          this.termsAndConditionURL =
            truckconnectivity?.data?.truck_connectivity_pdf?.url
        })
    }
  }
}
</script>

<style lang="scss">
.checkout {
  height: 100%;
  .v-navigation-drawer__content {
    overflow-y: hidden;
  }

  .loader {
    text-align: center;
    padding-top: 48px;
  }

  &_header {
    padding-top: 50px;
    padding-bottom: 22px;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;

    .checkout-title {
      font-size: 2rem;
      color: var(--v-dark-base);
      margin-left: 48px;
      font-weight: lighter;
      text-align: left !important;

      @media screen and (min-width: 500px) {
        font-size: 2.2rem;
      }
    }

    .close-btn {
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }

  &_main {
    margin-top: 150px;
    padding: 0 32px 22px;
    height: calc(100% - 336px);
    overflow: auto;

    @media screen and (min-width: 960px) {
      height: calc(100% - 270px);
      width: 720px;
    }

    .service-panel {
      &:not(.active) {
        margin-top: 16px;
        &:first-child {
          margin-top: 0;
        }
      }
      &.active {
        .service-panel-header {
          border-radius: 8px 8px 0 0;
          border-bottom: none;
        }
      }
      &-header {
        padding: 16px;
        border-radius: 8px;
        border: solid 1px var(--v-cloudy-base);
        background-color: var(--v-pale-two-base);
        color: var(--v-dark-base);

        &:before {
          padding: 16px;
          border-radius: 8px;
          border: solid 1px var(--v-cloudy-base);
          background-color: var(--v-pale-two-base);
        }

        .service-img {
          float: left;
          width: 40px;
          height: 40px;
        }

        .service-info {
          float: left;
          margin-left: 12px;
          line-height: 20px;

          .service-label {
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: bold;
            color: var(--v-bluegrey-base);
          }

          .service-name {
            font-size: 1rem;
            font-weight: bold;
          }
        }

        .service-resume {
          margin-left: 12px;
          line-height: 20px;

          .service-vehicles {
            text-transform: uppercase;
            font-size: 0.75rem;
          }

          .service-totals {
            font-size: 1rem;
          }
        }

        .remove-title-header {
          font-size: 1rem;
          font-weight: bold;
          color: var(--v-slate-base);
        }
      }

      &-content {
        padding: 30px 0 18px;
        color: var(--v-dark-base);
        border: solid 1px var(--v-cloudy-base);

        .v-expansion-panel-content__wrap {
          padding: 0;
        }

        h2 {
          font-size: 1.25rem;
          color: var(--v-dark-base);
          padding: 0 16px;
        }

        .options {
          display: flex;
          align-items: center;
          padding: 0 16px;

          a {
            margin: 0 10px;
            font-size: 0.875rem;
            text-decoration: underline;
          }

          .start-spacer {
            width: 20px;
            height: 20px;
            margin-top: 20px;
            margin-left: 8px;
            border-top: 1px solid var(--v-cloudy-base);
            border-left: 1px solid var(--v-cloudy-base);
          }

          .vertical-spacer {
            border-left: 1px solid var(--v-cloudy-base);
            color: var(--v-cloudy-base);
            height: 16px;
            margin-top: 2px;
          }

          .horizontal-spacer {
            border-top: 1px solid var(--v-cloudy-base);
            flex-grow: 1;
          }

          .sort-by-label {
            font-size: 0.875rem;
            font-weight: bold;
            text-align: right;
            color: var(--v-slate-base);
            margin: 0 10px;
            &.mobile {
              width: 100px;
              text-align: left;
            }
          }

          .sort-by-items {
            max-width: 170px;
            height: 32px;
            font-size: 0.875rem;
            &.mobile {
              width: 100%;
              max-width: none;
            }

            .v-input {
              &__slot {
                min-height: 32px !important;
              }

              &__append-inner {
                margin-top: 0 !important;
              }
            }
          }
        }

        .service-table {
          overflow-x: hidden;

          .v-data-table-header {
            display: none;
          }

          &-row {
            border-bottom: thin dashed rgba(0, 0, 0, 0.12) !important;
            padding: 16px 0 12px;

            &.unsubscribe {
              background-color: #c530300c;
              border-radius: 0;
            }

            .service-description {
              font-size: 1rem;
              padding: 0 16px;
            }

            .row {
              margin: 0;

              div[class*='col-'] {
                font-size: 1rem;
                font-weight: bold;
                color: var(--v-dark-base);
                text-transform: uppercase;
                padding: 0 4px;

                &:first-child {
                  padding-left: 16px;
                  width: 52px;
                }

                &:last-child {
                  padding-right: 16px;
                }

                .v-input--checkbox {
                  margin-top: 0;

                  .v-input--selection-controls__input {
                    width: 16px;
                    height: 16px;
                    border-radius: 4px;
                    background-color: var(--v-pale-three-base);

                    i {
                      color: var(--v-pale-three-base);
                    }
                  }
                }

                .table-header {
                  font-size: 0.75rem;
                  font-weight: bold;
                  color: var(--v-bluegrey-base);
                  text-transform: uppercase;
                  height: 18px;
                }

                .row-total {
                  text-align: right;
                  text-transform: lowercase;

                  &.unsubscribe {
                    color: var(--v-reddish-base);
                  }

                  span {
                    font-size: 0.875rem;
                    font-weight: normal;
                  }
                }
              }
            }

            .data-end-message {
              font-size: 0.75rem;
              color: var(--v-bluegrey-base);
              margin-left: 52px;
              margin-top: 8px;
            }
          }
        }

        .show-all {
          padding: 0 16px;
          margin: 10px 0;

          .v-btn {
            width: 100%;
            border-radius: 4px;
            background-color: var(--v-ice-base) !important;
            color: var(--v-peacock-base);
            font-size: 0.6875rem;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: normal;
          }
        }

        .total {
          padding: 0 16px;
          margin-top: 10px;

          .delete-order {
            letter-spacing: normal;
            text-transform: capitalize;
            text-decoration: underline;
            font-size: 0.75rem;
          }

          .total-label {
            font-size: 1rem;
            font-weight: bold;
            text-align: right;
            margin-right: 30px;
          }

          .total-value-label {
            font-size: 0.875rem;
          }

          .total-value {
            font-size: 1.125rem;
          }
        }

        .confirm-question {
          width: 512px;
          color: var(--v-reddish-base);
          font-size: 1rem;
          font-weight: bold;
          margin-left: 16px;
        }

        .select {
          position: relative;
          font-size: 0.875rem;
          .v-input__slot {
            border: 1px solid var(--v-pale-three-base) !important;
          }
          .v-icon {
            margin-top: 0 !important;
          }
        }

        .undo-removal {
          letter-spacing: normal;
          text-transform: capitalize;
          text-decoration: underline;
          font-size: 0.75rem;
        }

        .confirm-question {
          width: 512px;
          color: var(--v-reddish-base);
          font-size: 1rem;
          font-weight: bold;
          margin-left: 16px;
        }

        .select {
          position: relative;
          font-size: 0.875rem;
          .v-input__slot {
            border: 1px solid var(--v-pale-three-base) !important;
          }
          .v-icon {
            margin-top: 0 !important;
          }
        }

        .undo-removal {
          letter-spacing: normal;
          text-transform: capitalize;
          text-decoration: underline;
          font-size: 0.75rem;
        }

        .field-change-owner {
          position: relative;

          .v-input__slot {
            border: 1px solid var(--v-pale-three-base) !important;
          }
          .textfield {
            .placeholder {
              font-size: 0.75rem;
              color: var(--v-bluegrey-base);
            }
          }
        }
      }
    }

    .terms {
      border-radius: 8px;
      border: solid 1px var(--v-pale-three-base);
      background-color: var(--v-pale-two-base);
      margin-top: 32px;
      padding: 16px;
      font-size: 0.875rem;

      .check-terms {
        margin: 10px 0 0 0;

        label {
          font-size: 0.875rem;
          font-weight: bold;
          color: var(--v-dark-base);
        }
      }
    }
  }

  &_footer {
    padding: 20px 32px !important;
    background-color: white !important;
    border-top: 1px solid var(--v-cloudy-base) !important;
    margin-bottom: 20px !important;

    .total-price {
      font-size: 1.15rem;
      font-weight: bold;
      color: var(--v-bluegrey-base);
    }

    .alert-cdd-check {
      color: var(--v-reddish-base);
    }

    .v-btn {
      letter-spacing: normal;
      font-weight: bold;

      &.btn-continue {
        text-decoration: underline;
      }

      &.btn-pay {
        background-image: linear-gradient(
          to bottom,
          #266cab 0%,
          #01529c
        ) !important;
        &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          background-image: none !important;
          background-color: var(--v-cloudy-base) !important;
          color: white !important;
        }
      }

      &.btn-remove-vehicles {
        background-image: linear-gradient(
          to bottom,
          #c53030 0%,
          #9b2c2c
        ) !important;
        &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          background-image: none !important;
          background-color: var(--v-reddish-base) !important;
          color: white !important;
        }
      }
    }
  }
}
.vehiclevin-tooltip {
  min-height: 32px !important;
  border-radius: 16px !important;
  background: rgba(97, 97, 97, 0.9) !important;
  .vehiclevin-span {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
  }
}
</style>