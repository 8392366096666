<template>
  <div class="vehicles">
    <v-row
      v-if="loading"
      class="loader-page"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-row>
    <div v-else>
      <h1 v-if="textToSearch.length > 0">
        {{ $t('vehicles_overview.txt.search_results') }}
      </h1>
      <h1 v-else>
        {{ $t('vehicles_overview.txt.vehicles') }}
      </h1>
      
      <div class="results">
        <VehiclesSearchSlim
          :products="products"
          :products-prismic="productsPrismic"
          @productsFilter="filtersReceived"
          @textToSearch="textToSearchReceived"
          @sortBy="sortByReceived"
          @viewType="viewTypeReceived"
        />
        <v-data-table
          :headers="headers"
          :items="visibleItemsPerPages"
          :search="textToSearch"
          :loading="loading"
          loading-text="Loading... Please wait"
          loader-height="2"
          :footer-props="{
            'items-per-page-options': [1, 5, 10, 25, 50]
          }"
        >
          <template slot="no-data">
            <div
              v-if="noSearchResults"
              class="no-results"
            >
              <v-icon size="60">
                mdi-truck
              </v-icon>
              <br>{{ $t('vehicles_overview.txt.empty_search') }}
            </div>
          </template>

          <template v-slot:[`item.vin`]="{ item }">
            <span style="font-weight:bold;letter-spacing: 0.12rem;">
              {{ item.vin }}
            </span>
          </template>
          <template v-slot:[`item.activeProducts`]="{ item }">
            <!-- new -->
            <div
              order="5"
              cols="12"
              class="d-flex justify-center align-center"
              :class="{ 'col-lg-2 justify-lg-start order-lg-4': viewType == 'list' }"
            >
              <div
                v-if="item.activeProducts.length > 0"
                class="d-flex justify-center align-center"
              >
                <v-tooltip
                  v-for="(product, index) in item.activeProducts"
                  :key="index"
                  content-class="service-tooltip"
                  top
                  color="#3c366b"
                >
                  <template v-slot:activator="{ on }">
                    <v-img
                      class="service-icon"
                      :src="product.icon"
                      v-on="on"
                    />
                  </template>
                  <span class="tooltip-span">{{ product.name }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                <span class="noservices">{{ $t('vehicles_overvie.txt.no_services_selected') }}</span>
              </div>
            </div>
            <!-- end new -->
          </template>
          <template v-slot:[`item.actServ`]="{ item }">
            <v-col
              v-if="changeTrucks"
              order="6"
              cols="12"
              class="d-none align-center"
              :class="{ 'col-lg-1 d-lg-flex justify-lg-start': viewType == 'list' }"
            >
              <v-btn
                class="button elevation-0"
                :to="{ name: 'vehicle_details', params: { id: item.subscriptionNumber, productId: productID }}"
              >
                {{ $t('vehicles_overview.txt.configure') }}
              </v-btn>
            </v-col>
            <v-col
              v-else
              order="6"
              cols="12"
              class="d-none align-center"
              :class="{ 'col-lg-1 d-lg-flex justify-lg-start': viewType == 'list' }"
            >
              <v-btn
                class="button elevation-0"
                :to="{ name: 'vehicle_details', params: { id: item.subscriptionNumber, productId: productID }}"
              >
                {{ $t('vehicles_overview.txt.configure') }}
              </v-btn>
            </v-col>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-snackbar
      v-model="notification"
      top
      center
      :color="notificationType"
    >
      {{ notificationText }}
    </v-snackbar>
  </div>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import { mapState, mapMutations, mapActions } from 'vuex'
import VehiclesSearchSlim from '@/components/VehiclesSearchSlim.vue'
import moment from 'moment'

export default {
  name: 'VehiclesOverview',
  components: {
    VehiclesSearchSlim
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 8,
      products: [],
      productsPrismic: [],
      textToSearch: '',
      sortBy: '',
      filters: '',
      flagList: false,
      viewType: 'list',
      selected: [],
      selectedBy: '',
      checkSelected: false,
      changeTrucks: false,
      notification: false,
      notificationText: '',
      notificationType: 'success',
      loading: false,
      // #Paccar POC
      iconData: {},
      productID: '',
      headers: [
        { text: 'VIN', value: 'vin' },
        { text: 'UNIT NUMBER', value: 'unitNumber', class: 'column-header' },
        { text: 'ACTIVE SERVICES', value: 'activeProducts', sortable: false },
        { text: 'MANAGED BY', value: 'invoiceOwner' },
        { text: '', value: 'actServ' }
      ]
    }
  },
  computed: {
    ...mapState('account', ['currency']),
    ...mapState('trucks', ['trucks']),
    ...mapState('checkout', ['checkout']),

    noSearchResults() {
      return (
        (this.trucks && this.trucks.length == 0) ||
        (this.textToSearch.length > 0 && this.filteredList.length == 0) ||
        (this.filters.length > 0 && this.filteredList.length == 0)
      )
    },
    filteredList() {
      let filteredVehicles = this.trucks
      // const unFilteredVehicles = this.trucks
      if (this.filters && this.filters.length > 0) {
        let filterList = []
        filterList = this.filters
        filteredVehicles = filteredVehicles.filter(function(r) {
          return (
            r.activeServices &&
            filterList.every(x => r.activeServices.some(y => y.productId == x))
          )
        })
      }

      if (this.sortBy.key === 'vin') {
        filteredVehicles.sort((x, y) => {
          return x.vin < y.vin ? -1 : x.vin > y.vin ? 1 : 0
        })
      } else if (this.sortBy.key === 'unitNumber') {
        filteredVehicles.sort((x, y) => {
          if (x.unitNumber < y.unitNumber) return -1
          if (x.unitNumber > y.unitNumber) return 1
          return 0
        })
      } else if (this.sortBy.key === 'invoiceOwner') {
        filteredVehicles.sort((x, y) => {
          if (x.invoiceOwner < y.invoiceOwner) return -1
          if (x.invoiceOwner > y.invoiceOwner) return 1
          return 0
        })
      }
      return filteredVehicles
    },
    visibleItemsPerPages() {
      const visibleVehiclesPerPage = this.filteredList
      return this.getActiveIcons(visibleVehiclesPerPage)
    },
    selectedTrucksLength() {
      if (this.selected) return this.selected.length
      return 0
    },
    vehicleInCart() {
      const array = []
      const x =
        this.checkout && this.checkout.products
          ? this.checkout.products.filter(
              x => x.productID == 'scrapped' || x.productID == 'changeOwner'
            )
          : false
      if (x && x.length > 0) {
        x.forEach(product => {
          product.rateplans.forEach(rp => {
            array.push(...rp.subscriptions)
          })
        })
      }
      return array
    }
  },
  async created() {
    this.loading = true
    // await this.moment()
    // await this.getProductsPrismic()
    await this.getVehicles()
    await this.getProducts()
    this.setVehiclesList()
    this.loading = false
  },
  methods: {
    ...mapMutations('checkout', ['addTotalCheckout', 'setCheckout']),
    ...mapActions('trucks', ['getVehicles']),
    ...mapActions('notification', ['setSnackbar']),
    setVehiclesList() {
      this.trucks.forEach(fv => {
        this.evaluateActiveServices(fv)
      })
    },

    getProducts() {
      return apiCalls
        .get(api.ProductsGetAll, '', '?checkForActivatedVehicles=true')
        .then(res => (this.products = res.data))
    },

    getProductsPrismic() {
      return apiCalls
        .getCMSContent('resources', 'catalog')
        .then(({ results }) => (this.productsPrismic = results[0].data.options))
    },

    getIconUrl(name, active) {
      let url = ''
      // this.productsPrismic.forEach(icon => {
      //   if (icon.externalkey[0].text === name) {
      //     if (active == true)
      //       url =
      //         'https://paccar.cdn.prismic.io/paccar/d43f2de9-b324-494a-a9ce-2c45fbe71500_service-icon-daf-connect.svg'
      //     //icon.iconactive.url
      //     else
      //       url =
      //         'https://paccar.cdn.prismic.io/paccar/68afbc1b-dff8-40d9-be72-5c1552d08da3_service-icon-open-platform-inactive+%281%29.svg' // icon.icondisabled.url
      //   }
      // })
      if (active == true)
        url =
          'https://paccar.cdn.prismic.io/paccar/d43f2de9-b324-494a-a9ce-2c45fbe71500_service-icon-daf-connect.svg'
      //icon.iconactive.url
      else
        url =
          'https://paccar.cdn.prismic.io/paccar/68afbc1b-dff8-40d9-be72-5c1552d08da3_service-icon-open-platform-inactive+%281%29.svg' // icon.icondisabled.url

      return url
    },

    getActiveIcons(visibleItems) {
      visibleItems.forEach(truck => {
        const array = []
        let totalMonth = 0
        let totalYear = 0
        this.productID = this.products[0].id
        this.products.forEach(prod => {
          array.push({
            productId: prod.id,
            name: prod.name,
            sku: prod.sku,
            icon: this.getIconUrl(prod.sku, false),
            price: 0,
            period: ''
          })
        })
        array.sort((a, b) => {
          return a.name > b.name ? 1 : -1
        })
        array.forEach(ar => {
          const alreadyEvaluated = []
          truck.activeServices.forEach(rp => {
            if (
              rp.productId == ar.productId &&
              !alreadyEvaluated.includes(rp.productId)
            ) {
              ar.icon = this.getIconUrl(rp.productSku, true)
              rp.charges.forEach(rpc => {
                ar.price = rpc.price
                ar.period = rpc.billingPeriod
              })
              alreadyEvaluated.push(rp.productId)
            }
          })
          this.productsPrismic.forEach(pc => {
            if (pc.externalkey[0].text.includes(ar.sku))
              ar.name = pc.title[0].text
          })
          if (ar.period === 'Month') totalMonth = totalMonth + ar.price
          if (ar.period === 'Annual') totalYear = totalYear + ar.price
        })
        truck.totalMonth = totalMonth
        truck.totalYear = totalYear
        truck.activeProducts = array
      })
      return visibleItems
    },

    evaluateActiveServices(truck) {
      truck.activeServices = []
      this.products.forEach(ar => {
        const orderedRatePlans = this.sortRatePlans(truck.ratePlans)
        const alreadyEvaluated = []
        orderedRatePlans.forEach(rp => {
          if (
            rp.productId == ar.id &&
            !alreadyEvaluated.includes(rp.productId)
          ) {
            const currentDate = new Date()
            const charges = rp.charges.filter(
              x =>
                x.EffectiveEndDate != null &&
                Date.parse(x.EffectiveEndDate) >= currentDate
            )
            if (charges.length > 0 && rp.lastChangeType != 3) {
              truck.activeServices.push(rp)
            }
            alreadyEvaluated.push(rp.productId)
          }
        })
      })
    },

    filtersReceived(servicesFiltered) {
      this.page = 1
      this.filters = servicesFiltered
    },
    textToSearchReceived(search) {
      this.page = 1
      return (this.textToSearch = search)
    },
    sortByReceived(sortBy) {
      this.page = 1
      return (this.sortBy = sortBy)
    },
    viewTypeReceived(viewType) {
      return (this.viewType = viewType)
    },
    selectByItem(selectByReceived) {
      this.selectedBy = selectByReceived
      if (this.selectedBy === 'all') {
        this.checkSelected = true
        this.selected = this.filteredList.filter(
          x => this.disabledVehicleInCart(x.subscriptionNumber) === false
        )
      } else if (this.selectedBy === 'thisPage') {
        this.checkSelected = true
        this.selected = this.visibleItemsPerPages.filter(
          x => this.disabledVehicleInCart(x.subscriptionNumber) === false
        )
      }
    },
    setSelectedTrucks() {
      if (this.checkSelected) {
        this.selected = this.visibleItemsPerPages.filter(
          x => this.disabledVehicleInCart(x.subscriptionNumber) === false
        )
      } else this.selected = []
    },
    activateChangeTrucks(event) {
      this.changeTrucks = event
      this.selected = []
    },
    handleClick(subscriptionNumber, id) {
      if (!this.changeTrucks) {
        this.$router.push({
          name: 'vehicle_details',
          params: { id: subscriptionNumber, productId: id }
        })
      }
    },
    disabledVehicleInCart(subscriptionNumber) {
      return this.vehicleInCart.some(
        x => x.subscriptionID == subscriptionNumber
      )
    },
    bulkChangeOwner() {
      this.selected.forEach(truck => {
        apiCalls
          .put(api.CartAdd, '', '', {
            subscriptionID: truck.subscriptionNumber,
            productRateplanID: '',
            rateplanID: '',
            productRateplanChargeID: '',
            productID: 'changeOwner',
            addSubscription: 0,
            metaData: {
              newOwner: '',
              cancelationDate: moment().format('YYYY-MM-DD')
            }
          })
          .then(res => {
            if (res.status == 200) {
              this.setCheckout(res.data.products)
              this.addTotalCheckout(this.selected.length)
              this.setSnackbar({
                showing: true,
                type: 'success',
                text: this.$t('vehicles_overview.txt.notification_success')
              })
              this.selected = []
              this.changeTrucks = false
            } else {
              this.setSnackbar({
                showing: true,
                type: 'error',
                text: this.$t('vehicles_overview.txt.notification_error')
              })
            }
          })
      })
    },
    sortRatePlans(ratePlans) {
      ratePlans.sort((x, y) => {
        if (x.updated_Timestamp__c < y.updated_Timestamp__c) return 1
        if (x.updated_Timestamp__c > y.updated_Timestamp__c) return -1
        return 0
      })
      return ratePlans
    },
    bulkRemoveVeTicle() {
      this.selected.forEach(truck => {
        apiCalls
          .put(api.CartAdd, '', '', {
            subscriptionID: truck.subscriptionNumber,
            productRateplanID: '',
            rateplanID: '',
            productRateplanChargeID: '',
            productID: 'scrapped',
            addSubscription: 0,
            metaData: { cancelationDate: moment().format('YYYY-MM-DD') }
          })
          .then(res => {
            if (res.status == 200) {
              this.setCheckout(res.data.products)
              this.addTotalCheckout(this.selected.length)
              this.setSnackbar({
                showing: true,
                type: 'success',
                text: this.$t('vehicles_overview.txt.notification_success')
              })
              this.selected = []
              this.changeTrucks = false
            } else {
              this.setSnackbar({
                showing: true,
                type: 'error',
                text: this.$t('vehicles_overview.txt.notification_error')
              })
            }
          })
      })
    }
  }
}
</script>
<style lang="scss">
.vehicles {
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  h1 {
    margin-bottom: 22px;
    font-family: 'Arial Narrow', Arial, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: left;
  }
  .no-results {
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    color: var(--v-bluegrey-base);
    .v-icon {
      color: var(--v-cloudy-base);
    }
  }
  .results {
    margin-top: 32px;
    margin-bottom: 72px;
    &_info {
      font-size: 1rem;
      line-height: normal;
      color: var(--v-slate-base);
      .active-change-trucks {
        width: 100%;
        height: 32px;
        border-radius: 3px;
        background-color: var(--v-peacock-base) !important;
        color: white;
        font-size: 0.7rem;
      }
      .disable-change-trucks {
        width: 100%;
        height: 32px;
        border-radius: 3px;
        border: solid 1px var(--v-cloudy-base);
        background-color: white !important;
        color: var(--v-steel-base);
        font-size: 0.7rem;
      }
    }
    &_table {
      margin-bottom: 25px;
      .header {
        padding: 0 12px;
        .col {
          text-transform: uppercase;
          font-size: 0.8125rem;
          font-weight: 500;
          line-height: normal;
          color: var(--v-bluegrey-base);
          padding: 10px;
        }
        .check-item {
          margin: 0;
          padding: 0;
          .v-input--selection-controls__input {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            background-color: var(--v-pale-three-base);

            i {
              color: var(--v-pale-three-base);
            }
          }
        }
      }
      .body {
        .item {
          .v-card {
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
            color: var(--v-slate-base);
            &:hover {
              background-color: var(--v-pale-two-base);
            }
            &.selected {
              background-color: var(--v-peacock-base);
              color: white;
              .v-input--selection-controls__input {
                background-color: var(--v-peacock-base) !important;
                i {
                  color: var(--v-pale-three-base) !important;
                }
              }
              .vehiclename {
                color: white !important;
              }
              .platenumber {
                color: white !important;
              }
              .type {
                color: white !important;
              }
            }
            .vehiclename {
              font-size: 0.875rem;
              font-weight: bold;
              white-space: nowrap;
              letter-spacing: 0.1em;
              text-overflow: clip;
              cursor: pointer;
              color: black;
              line-height: normal;
            }
            .platenumber {
              line-height: normal;
              color: var(--v-slate-base);
              font-size: 1.125rem;
              font-weight: bold;
              &.list-text {
                font-size: 1rem;
                font-weight: normal;
              }
            }
            .type {
              line-height: normal;
              color: var(--v-slate-base);
              font-size: 0.75rem;
              font-weight: bold;
              &.list-text {
                font-size: 1rem;
                font-weight: normal;
              }
            }
            .list-row {
              height: 80px !important;
            }
            .truck-img-placeholder {
              height: 100px;

              .truck-img {
                max-width: 80px;
              }
            }
            .service-icon {
              width: 30px;
              height: 30px;
              float: left;
            }
            .noservices {
              font-size: 0.6875rem;
              font-weight: bold;
              color: var(--v-bluegrey-base);
              text-transform: uppercase;
            }
            .button {
              font-size: 0.75rem;
              font-weight: bold;
              line-height: normal;
              text-align: center;
              color: var(--v-mid-base);
              width: 110px;
              height: 32px;
              border-radius: 3px;
              background-color: var(--v-ice-base);
            }
            .check-item {
              .v-input--selection-controls__input {
                width: 16px;
                height: 16px;
                border-radius: 4px;
                background-color: var(--v-pale-three-base);

                i {
                  color: var(--v-pale-three-base);
                }
              }
            }
            .grid-totals {
              height: 72px;
            }
          }
        }
      }
      .options {
        display: flex;
        align-items: center;
        padding-left: 16px;

        a {
          margin: 0 10px;
          font-size: 0.875rem;
          text-decoration: underline;
        }

        .start-spacer {
          width: 20px;
          height: 20px;
          margin-top: 20px;
          margin-left: 12px;
          border-top: 1px solid var(--v-cloudy-base);
          border-left: 1px solid var(--v-cloudy-base);
        }

        .vertical-spacer {
          border-left: 1px solid var(--v-cloudy-base);
          color: var(--v-cloudy-base);
          height: 16px;
          margin-top: 2px;
        }

        .horizontal-spacer {
          border-top: 1px solid var(--v-cloudy-base);
          flex-grow: 1;
        }

        .filter-label {
          font-size: 0.875rem;
          font-weight: bold;
          text-align: right;
          color: var(--v-slate-base);
          margin: 0 10px;
          &.mobile {
            text-align: left;
            width: 100px;
          }
        }

        .filter-items {
          max-width: 170px;
          height: 32px;
          font-size: 0.875rem;
          &.mobile {
            width: 100%;
            max-width: none;
          }

          .v-input {
            &__slot {
              min-height: 32px !important;
            }

            &__append-inner {
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    &_pagination {
      .v-pagination {
        &__item {
          font-weight: bold;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          margin: 0 8px;
          &:focus {
            outline: none !important;
          }
          &--active {
            background-color: var(--v-secondary-base) !important;
            color: var(--v-darkgrey-blue-base) !important;
            box-shadow: none;
          }
        }
        &__navigation {
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          margin: 0 40px;
        }
        .v-icon {
          font-size: 1.25rem;
          color: var(--v-slate-base);
        }
      }
    }
    &_footer {
      &_btn {
        width: 192px;
        height: 48px !important;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        &.change-owner {
          background-image: linear-gradient(to bottom, #266cab 0%, #01529c);
          color: white;
        }
        &.remove-vehicle {
          background-image: linear-gradient(
            to bottom,
            #c53030 0%,
            #9b2c2c 100%
          );
          color: white;
        }
        &.disabled {
          background-color: var(--v-cloudy-base) !important;
          color: white !important;
        }
      }
      &_costs {
        font-size: 1.25rem;
        color: var(--v-slate-base);
      }
      &_chooseperiod {
        .label {
          font-size: 0.75rem;
          font-weight: bold;
          color: var(--v-peacock-base);
          text-transform: uppercase;
        }
        .buttons {
          .v-btn {
            width: 128px;
            height: 32px;
            border-radius: 3px;
            border: solid 1px var(--v-cloudy-base);
            background-color: white !important;
            font-size: 0.75rem;
            text-align: center;
            color: var(--v-steel-base);

            &.v-btn--active {
              background-color: var(--v-peacock-base) !important;
              color: white !important;
            }

            &.disabled {
              color: white !important;
            }
          }
        }
      }
    }
  }
}
.service-tooltip {
  height: 34px !important;
  min-height: 34px !important;
  background: rgb(60, 54, 107) !important;
  .tooltip-span {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
  }
  &:before {
    content: '';
    display: block;
    width: 0 !important;
    height: 0 !important;
    border-top: 0 !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid var(--v-darkgrey-blue-two-base) !important;
    -webkit-transform: rotate(225deg) !important;
    position: absolute !important;
    top: 27px !important;
    left: 45% !important;
  }
}
.vehiclevin-tooltip {
  min-height: 32px !important;
  border-radius: 16px !important;
  background: rgb(74, 85, 104) !important;
  .vehiclevin-span {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
  }
}
.container .row .vehicle-overview {
  padding: 12px 0px;
}
.column-header {
  font-weight: bold;
  color: #999;
}

.button {
  font-size: 0.75rem !important;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: var(--v-mid-base) !important;
  width: 110px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--v-ice-base) !important;
}
</style>