import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '../auth'
import Login from './views/login.vue'
import VehicleDetail from './views/vehicle-details.vue'
import Home from './views/home.vue'
import ServiceOverview from './views/services-overview.vue'
import VehiclesOverview from './views/vehicles-overview.vue'
import ServiceManagement from './views/service-details.vue'
import Logout from './views/search-account.vue'
import PaymentMethod from './views/payment-method.vue'
import Invoices from './views/invoices.vue'
import Error from './views/error.vue'
import PaymentInformation from './views/payment-information.vue'
import NoAccess from './views/no-access.vue'

Vue.use(VueRouter)

const connection = process.env.VUE_APP_AUTH0_CONNECTION || ''

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true,
    meta: { hideMenu: true }
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: NoAccess,
    meta: { hideMenu: true }
  },
  {
    path: '/search-account',
    name: 'search',
    component: Logout,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection)
  },
  {
    path: '/payment-information',
    name: 'payment_information',
    component: PaymentInformation,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection)
  },
  {
    path: '/payment-method',
    name: 'payment_method',
    component: PaymentMethod,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection)
  },
  {
    path: '/vehicle-details/:id/:productId',
    name: 'vehicle_details',
    component: VehicleDetail,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection),
    props: true
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection),
    redirect: { name: 'services' },
    children: [
      {
        path: 'services',
        name: 'services',
        component: ServiceOverview
      },
      {
        path: 'vehicles',
        name: 'vehicles',
        component: VehiclesOverview
      }
    ]
  },
  {
    path: '/service-detail/:id',
    name: 'service-detail',
    component: ServiceManagement,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection)
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: Invoices,
    beforeEnter: (to: any, from: any, next: any) =>
      authGuard(to, from, next, connection)
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: { hideMenu: true }
  }
]

const router = new VueRouter({ mode: 'history', routes })

export default router
