import Vue from 'vue'
import Vuex from 'vuex'
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'

Vue.use(Vuex)

const state = {
  trucks: [],
  billingPeriodStore: []
}
const mutations = {
  setTrucks(state, trucks) {
    state.trucks = trucks
  },
  getBillingPeriod(state, item) {
    state.billingPeriodStore.forEach(bp => {
      if (bp.subscriptionNumber == item.subscriptionNumber)
        bp.billingPeriod = item.billingPeriod
    })
    localStorage.setItem(
      'billingPeriod',
      JSON.stringify(state.billingPeriodStore)
    )
  },
  setBillingPeriod(state, billingPeriodStore) {
    state.billingPeriodStore = billingPeriodStore
  }
}

const actions = {
  async getVehicles({ commit }) {
    const res = await apiCalls.get(
      api.TrucksGetAll.replace('//', '/'),
      '',
      `?pageIndex=1&productId=&accountNumber=`
    )
    commit('setTrucks', res.data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
