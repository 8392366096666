var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vehicles"},[(_vm.loading)?_c('v-row',{staticClass:"loader-page"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[(_vm.textToSearch.length > 0)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t('vehicles_overview.txt.search_results'))+" ")]):_c('h1',[_vm._v(" "+_vm._s(_vm.$t('vehicles_overview.txt.vehicles'))+" ")]),_c('div',{staticClass:"results"},[_c('VehiclesSearchSlim',{attrs:{"products":_vm.products,"products-prismic":_vm.productsPrismic},on:{"productsFilter":_vm.filtersReceived,"textToSearch":_vm.textToSearchReceived,"sortBy":_vm.sortByReceived,"viewType":_vm.viewTypeReceived}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.visibleItemsPerPages,"search":_vm.textToSearch,"loading":_vm.loading,"loading-text":"Loading... Please wait","loader-height":"2","footer-props":{
          'items-per-page-options': [1, 5, 10, 25, 50]
        }},scopedSlots:_vm._u([{key:"item.vin",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold","letter-spacing":"0.12rem"}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"item.activeProducts",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center",class:{ 'col-lg-2 justify-lg-start order-lg-4': _vm.viewType == 'list' },attrs:{"order":"5","cols":"12"}},[(item.activeProducts.length > 0)?_c('div',{staticClass:"d-flex justify-center align-center"},_vm._l((item.activeProducts),function(product,index){return _c('v-tooltip',{key:index,attrs:{"content-class":"service-tooltip","top":"","color":"#3c366b"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"service-icon",attrs:{"src":product.icon}},on))]}}],null,true)},[_c('span',{staticClass:"tooltip-span"},[_vm._v(_vm._s(product.name))])])}),1):_c('div',[_c('span',{staticClass:"noservices"},[_vm._v(_vm._s(_vm.$t('vehicles_overvie.txt.no_services_selected')))])])])]}},{key:"item.actServ",fn:function(ref){
        var item = ref.item;
return [(_vm.changeTrucks)?_c('v-col',{staticClass:"d-none align-center",class:{ 'col-lg-1 d-lg-flex justify-lg-start': _vm.viewType == 'list' },attrs:{"order":"6","cols":"12"}},[_c('v-btn',{staticClass:"button elevation-0",attrs:{"to":{ name: 'vehicle_details', params: { id: item.subscriptionNumber, productId: _vm.productID }}}},[_vm._v(" "+_vm._s(_vm.$t('vehicles_overview.txt.configure'))+" ")])],1):_c('v-col',{staticClass:"d-none align-center",class:{ 'col-lg-1 d-lg-flex justify-lg-start': _vm.viewType == 'list' },attrs:{"order":"6","cols":"12"}},[_c('v-btn',{staticClass:"button elevation-0",attrs:{"to":{ name: 'vehicle_details', params: { id: item.subscriptionNumber, productId: _vm.productID }}}},[_vm._v(" "+_vm._s(_vm.$t('vehicles_overview.txt.configure'))+" ")])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[(_vm.noSearchResults)?_c('div',{staticClass:"no-results"},[_c('v-icon',{attrs:{"size":"60"}},[_vm._v(" mdi-truck ")]),_c('br'),_vm._v(_vm._s(_vm.$t('vehicles_overview.txt.empty_search'))+" ")],1):_vm._e()])],2)],1)]),_c('v-snackbar',{attrs:{"top":"","center":"","color":_vm.notificationType},model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_vm._v(" "+_vm._s(_vm.notificationText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }