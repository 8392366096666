<template>
  <v-container class="content">
    <v-row
      v-if="loading"
      class="loader-page"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-row>
    <div v-else>
      <v-row>
        <v-col
          cols="12"
          lg="3"
          class="pr-lg-12"
        >
          <router-link
            class="all-services"
            :to="{name: 'services', params: {flagVehicles: false}}"
          >
            <v-icon
              color="peacock"
              width="15px"
            >
              mdi-arrow-left
            </v-icon>
            <span class="ml-4">{{ $t('service_details.txt.all_services') }}</span>
          </router-link>
          <v-card
            outlined
            class="mt-12 detail-card"
          >
            <ServiceDetailCard
              :service="service"
              :service-annual-price="serviceAnnualPrice"
              :service-month-price="serviceMonthPrice"
              :month="monthlyCostToSend"
              :year="yearlyCostToSend"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="9"
        >
          <ServiceDetailList
            :service="service"
            @monthlyCost="monthlyCostReceived"
            @yearlyCost="yearlyCostReceived"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ServiceDetailCard from '@/components/ServiceDetailCard.vue'
import ServiceDetailList from '@/components/ServiceDetailList.vue'
import { roleRatePlanService } from '@/services/roleRatePlanService'
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import { mapState } from 'vuex'

export default {
  name: 'ServiceManagement',

  components: {
    ServiceDetailCard,
    ServiceDetailList
  },

  data: () => ({
    service: {},
    monthlyCostToSend: 0,
    yearlyCostToSend: 0,
    serviceAnnualPrice: 0,
    serviceMonthPrice: 0,
    loading: false
  }),
  computed: {
    ...mapState('account', ['currency'])
  },
  watch: {
    // currency(newValue) {
    //   if (newValue && newValue.code) {
    //     this.setServicePrice(this.service, newValue)
    //   }
    // }
  },
  async created() {
    this.loading = true
    await this.getServiceInformation()
    this.loading = false
  },
  methods: {
    getServiceInformation() {
      const id = this.$router.history.current.params.id
      return apiCalls
        .get(api.ProductsGetAll, '', '?checkForActivatedVehicles=false')
        .then(res => {
          this.service = res.data.find(service => service.id === id)

          if (this.service) {
            this.setServicePrice(this.service, this.currency)

            return apiCalls
              .getCMSContent('resources', 'catalog')
              .then(({ results }) => {
                results[0].data.options.find(cms => {
                  if (cms.externalkey[0].text === this.service.sku) {
                    this.service.serviceName = cms.title[0].text
                    this.service.iconUrl = cms.iconactive.url
                    this.service.description = cms.description[0].text
                  }
                })
              })
          }
        })
    },
    setServicePrice(newService, newCurrency) {
      newService.productRatePlans
        .filter(
          w =>
            w.ratePlanType__c ==
            roleRatePlanService.userRatePlan(localStorage.getItem('role'))
        )
        .forEach(product => {
          product.productRatePlanCharges.forEach(bp => {
            if (bp.billingPeriod === 'Annual') {
              const annualprice = bp.pricing.find(
                pr => pr.currency === newCurrency.code
              )
              this.serviceAnnualPrice = annualprice && annualprice.price
            } else if (bp.billingPeriod === 'Month') {
              const monthPrice = bp.pricing.find(
                pr => pr.currency === newCurrency.code
              )

              this.serviceMonthPrice = monthPrice && monthPrice.price
            }
          })
        })
    },

    monthlyCostReceived(monthlyCost) {
      this.monthlyCostToSend = monthlyCost
    },
    yearlyCostReceived(yearlyCost) {
      this.yearlyCostToSend = yearlyCost
    }
  }
}
</script>

<style lang="scss">
.content {
  margin-bottom: 260px;

  @media screen and (min-width: 600px) {
    margin-bottom: 200px;
  }
  @media screen and (min-width: 960px) {
    margin-bottom: 140px;
  }
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  .all-services {
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--v-mid-base);
    text-transform: uppercase;
    text-decoration: none;
  }
  .detail-card {
    border-radius: 8px !important;
  }
}
</style>