import Vue from 'vue'
import Vuex from 'vuex'
import checkout from './modules/checkout'
import account from './modules/account'
import trucks from './modules/trucks'
import notification from './modules/notification'
// import locale from './modules/locale'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

Vue.use(Vuex)
// when encodingType not specified base 64 is used
const ls = new SecureLS({
  // encodingType: 'rc4', // changeable
  isCompression: false,
  encryptionSecret: 'WebShop@123' // change this
})

export default new Vuex.Store({
  modules: {
    checkout,
    account,
    trucks,
    notification
    // locale
  },
  plugins: [
    createPersistedState({
      paths: ['account', 'checkout'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
  // plugins: [createPersistedState()]
})
