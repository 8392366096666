<template>
  <div class="service-detail-list">
    <v-row class="page-title">
      <v-col
        cols="12"
      >
        <h3
          class="d-inline pa-3 accent-3 white--text"
        >
          {{ service.name }}
        </h3>
      </v-col>
    </v-row>
    <ServiceDetailSearch
      @textToSearch="textToSearchReceived"
      @selectAllByPeriod="selectAllByPeriod"
      @filterByStatus="filterByStatus"
      @filterByPeriod="filterByPeriod"
      @sortBy="sortByReceived"
    />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredList"
      loader-height="2"
      :loading="loading"
      loading-text="Loading vehicles..."
      :footer-props="{
        'items-per-page-options': [10, 25, 50]
      }"
      item-key="vin"
      show-select
      :single-select="false"
      class="elevation-1"
      :item-class="evaluateToSunscreen"
      @update:page="onTablePageUpdate"
      @update:items-per-page="onTableRowCountUpdate"
      @toggle-select-all="setSelectedTrucks"
    >
      <template v-slot:[`item.data-table-select`]="{ item, isSelected }">
        <v-checkbox   
          :value="isSelected"
          :disabled="disabledVehicleInCart(item.subscriptionNumber)"
          @change="setSelectedTruck(item)"
        />
      </template>
      <template slot="no-data">
        <div
          v-if="noSearchResults"
          class="no-results"
        >
          <v-icon size="50">
            mdi-truck
          </v-icon>
          <br>{{ $t('vehicles_overview.txt.empty_search') }}
        </div>
      </template>

      <template v-slot:[`item.vin`]="{ item }">
        <span
          class="vehicle-name"
        >
          {{ item.vin }}
        </span>
      </template>
      <template v-slot:[`item.unitNumber`]="{ item }">
        <span>
          {{ item.unitNumber }}
        </span>
      </template>
      <template v-slot:[`item.subscriptionEndDate`]="{ item }">
        <span>
          {{ item.subscriptionEndDate }}
        </span>
      </template>
      <template v-slot:[`item.invoiceOwner`]="{ item }">
        <span>
          {{ item.invoiceOwner }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-col
          :md="item.type != 'Month' && item.type != 'Annual' && item.type != 'Three_Years' ? 2 : 2"
          class="d-none d-sm-block text-right align-self-center"
        >
          <v-btn
            :class="selected.includes(item) ? 'buttonVisibility' : ''"
            class="show-button elevation-0"
            @click="viewVehicleDetail(item.subscriptionNumber, productID)"
          >
            {{ $t('service_details.txt.show_vehicle') }}
          </v-btn>
        </v-col>
      </template>
    </v-data-table>
    <v-footer
      fixed
      color="white"
      class="py-6 elevation-2"
    >
      <v-container class="py-0">
        <v-row class="results_footer">
          <v-col
            class="py-2 py-md-0 text-center text-sm-left align-self-center"
            cols="12"
            sm="4"
            md="2"
            lg="1"
            offset-lg="4"
          >
            <v-icon
              width="22"
              color="steel"
            >
              mdi-truck
            </v-icon>
            <span>
              x <b class="results_footer_costs">{{ selectedForCart.length }} </b>
            </span>
          </v-col>
          <v-col
            class="py-2 py-md-0 text-center text-sm-right align-self-center"
            cols="12"
            sm="8"
            md="4"
            lg="3"
          >
            <div v-if="filterStatus.key === 'Unsubscribe Trucks' || filterStatus.key === 'Transfer Trucks'">
              <!-- <span>
                <b class="results_footer_costs"> - {{ currency.symbol }} {{ monthlyCost }}</b> {{ $t('service_details.txt.month') }}
                <span class="mx-3">&</span>
                <b class="results_footer_costs"> - {{ currency.symbol }} {{ yearlyCost }}</b> {{ $t('service_details.txt.year') }}
              </span> -->
            </div>
            <div
              v-else
              class="results_footer_chooseperiod"
            >
              <div class="d-flex buttons elevation-0 align-center justify-sm-end mr-2">
                <span class="label mr-2">{{ $t('service_details.txt.choose_period') }}</span>
                <v-btn
                  v-if="selectedHasMonthlyPlan.length>0"
                  class="elevation-0 ml-4"
                  :class="activeBtn.month ? 'v-btn--active' : 'disabled'"
                  :disabled="selected.length === 0 || (selected.length > 0 && selected[0].billingPeriod == 'Yearly') || !(selectedHasMonthlyPlan.length > 0)"
                  @click="onActiveButtonMonthClicked()"
                >
                  <span>
                    <b>{{ currency.symbol }} {{ monthlyCost }}</b> {{ $t('service_details.txt.month') }}
                  </span>
                </v-btn>
                <v-btn
                  class="elevation-0"
                  :class="activeBtn.annual ? 'v-btn--active' : 'disabled'"
                  :disabled="selected.length === 0 || (selected.length > 0 && selected[0].billingPeriod == 'Monthly') || !(selectedHasYearlyPlan.length > 0)"
                  @click="onActiveButtonAnnualClicked()"
                >
                  <span>
                    <b>{{ currency.symbol }} {{ yearlyCost }}</b> {{ $t('service_details.txt.year') }}
                  </span>
                </v-btn>
                <v-icon class="d-none d-md-block">
                  mdi-chevron-right
                </v-icon>
              </div>
            </div>
          </v-col>
          <v-col
            class="py-2 py-md-0 text-center text-md-right align-self-center"
            cols="12"
            md="4"
            lg="3"
          >
            <v-btn
              v-if="filterStatus.key === 'Unsubscribe Trucks'"
              class="results_footer_btn"
              :class="itemsForCart.length == 0 ? 'disabled' : 'cancel'"
              :disabled="itemsForCart.length == 0"
              @click="bulkStopService()"
            >
              {{ $t('service_details.txt.unsubscribe') }} 
            </v-btn>
            <v-btn
              v-else-if="filterStatus.key === 'Transfer Trucks'"
              class="results_footer_btn"
              :class="itemsForCart.length == 0 ? 'disabled' : 'transfer'"
              :disabled="itemsForCart.length == 0"
              @click="bulkTransferOwnerService()"
            >
              {{ $t('service_details.txt.transfer_owner') }}  
            </v-btn>
            <v-btn
              v-else
              class="results_footer_btn"
              :class="disabledButton || itemsForCart.length == 0 ? 'disabled' : 'proceed'"
              :disabled="disabledButton || itemsForCart.length == 0"
              @click="bulkActiveService()"
            >
              {{ $t('service_details.txt.subscribe') }} 
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import basketService from '@/services/basketService'
import { roleRatePlanService } from '@/services/roleRatePlanService'
import ServiceDetailSearch from '@/components/ServiceDetailSearch.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'ServiceDetailList',

  filters: {
    capitalize: function(value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    }
  },
  components: {
    ServiceDetailSearch
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      addToCartLimit: 60,
      productID: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      serviceInfo: [],
      selected: [],
      textToSearch: '',
      filterStatus: {
        key: 'Subscribe Trucks',
        text: this.$t('service_details.txt.subscribe_trucks')
      },
      filterPeriod: null,
      selectedBy: '',
      sortBy: {
        key: 'activated',
        text: ''
      },
      selectedForCart: [],
      checkSelected: true,
      activeBtn: { annual: false },
      notification: false,
      notificationText: '',
      notificationType: 'success',
      loading: false,
      trucks: [],
      headers: [
        { text: 'VIN', value: 'vin' },
        { text: 'UNIT NUMBER', value: 'unitNumber', class: 'column-header' },
        { text: 'SUB END DATE', value: 'subscriptionEndDate' },
        { text: 'MANAGED BY', value: 'invoiceOwner', sortable: false },
        { text: 'ACTION', value: 'action', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('account', [
      'currency',
      'accountDetails',
      'accountInformation',
      'payload',
      'appContext'
    ]),
    // ...mapState('account', ['currency', 'payload']),
    ...mapState('checkout', ['checkout']),

    //#region #Paccar-POC
    productPrices() {
      const pricingList = []
      this.service.productRatePlans.forEach(rp => {
        if (rp.productRatePlanCharges) {
          rp.productRatePlanCharges.forEach(ch => {
            pricingList.push({
              productRatePlanId: rp.id,
              prodRatePlanChargeId: ch.id,
              ratePlanType: rp.ratePlanType__c,
              servicePeriod: ch.billingPeriod,
              subscriptionLevel: 'Truck',
              servicePrice: ch.pricing.map(pricing => {
                return {
                  currency: pricing.currency,
                  price: pricing.price
                }
              })
            })
          })
        }
      })

      return pricingList
    },

    //#endregion

    selectedHasYearlyPlan() {
      // #Paccar-POC
      // const itemsWithAPlan = this.selected.filter(item => {
      //   const itemAnnual = item.totalCost.filter(cost => {
      //     return cost.servicePeriod === 'Annual'
      //   })
      //   return itemAnnual.length > 0
      // })
      // return itemsWithAPlan

      return this.selected.filter(truck => truck.billingPeriod.match(/Yearly/i))
    },
    selectedHasMonthlyPlan() {
      // #Paccar-POC
      // console.log('hasMontlhyPlan', this.selected)
      // const itemsWithAPlan = this.selected.filter(item => {
      //   const itemAnnual = item.totalCost.filter(cost => {
      //     return cost.servicePeriod === 'Monthly'
      //   })
      //   return itemAnnual.length > 0
      // })
      // return itemsWithAPlan

      return this.selected.filter(truck =>
        truck.billingPeriod.match(/Monthly/i)
      )
    },
    noSearchResults() {
      return (
        (this.trucks && this.trucks.length == 0) ||
        (this.textToSearch.length > 0 && this.filteredList.length == 0) ||
        this.filteredList.length == 0
      )
    },
    disabledButton() {
      if (
        Object.keys(this.activeBtn).length === 0 ||
        (!this.activeBtn.annual && !this.activeBtn.month)
      )
        return true
      return false
    },
    monthlyCost() {
      // #Paccar-POC
      //const cost = this.items
      const cost = this.itemsForCart
        .filter(item => item.monthlyCost)
        .reduce((acc, item) => acc + item.monthlyCost, 0)
      if (
        this.filterStatus.key === 'Unsubscribe Trucks' ||
        this.filterStatus.key === 'Transfer Trucks'
      ) {
        const activeCost = cost * -1
        this.$emit('monthlyCost', activeCost)
      } else {
        if (this.activeBtn.month) {
          this.$emit('monthlyCost', cost)
        } else {
          this.$emit('monthlyCost', 0)
        }
      }
      return cost
    },
    yearlyCost() {
      // #Paccar-POC
      //const cost = this.items
      const cost = this.itemsForCart
        .filter(item => item.yearlyCost)
        .reduce((acc, item) => acc + item.yearlyCost, 0)
      if (
        this.filterStatus.key === 'Unsubscribe Trucks' ||
        this.filterStatus.key === 'Transfer Trucks'
      ) {
        const activeCost = cost * -1
        this.$emit('yearlyCost', activeCost)
      } else {
        if (this.activeBtn.annual) {
          this.$emit('yearlyCost', cost)
        } else {
          this.$emit('yearlyCost', 0)
        }
      }
      return cost
    },

    filteredList() {
      let filteredVehicles = this.trucks
      const unFlteredVehicles = this.trucks
      if (this.textToSearch) {
        filteredVehicles = unFlteredVehicles.filter(vehicle => {
          return (
            (vehicle.truckName &&
              vehicle.truckName
                .toLowerCase()
                .includes(this.textToSearch.toLowerCase())) ||
            (vehicle.plateNumber &&
              vehicle.plateNumber
                .toLowerCase()
                .includes(this.textToSearch.toLowerCase())) ||
            (vehicle.vin &&
              vehicle.vin
                .toLowerCase()
                .includes(this.textToSearch.toLowerCase())) ||
            (vehicle.type &&
              vehicle.type
                .toLowerCase()
                .includes(this.textToSearch.toLowerCase())) ||
            (vehicle.activated &&
              vehicle.activated
                .toLowerCase()
                .includes(this.textToSearch.toLowerCase())) ||
            (vehicle.unitNumber &&
              vehicle.unitNumber
                .toLowerCase()
                .includes(this.textToSearch.toLowerCase()))
          )
        })
      }
      if (this.filterStatus.key === 'Unsubscribe Trucks') {
        filteredVehicles = filteredVehicles.filter(
          v =>
            v.activated && v.invoiceOwnerAccountId === this.appContext.childId
        )
      } else if (this.filterStatus.key === 'Transfer Trucks') {
        filteredVehicles = filteredVehicles.filter(
          v =>
            v.activated &&
            v.invoiceOwnerAccountId !== '' &&
            v.invoiceOwnerAccountId !== this.appContext.childId
        )
      } else {
        filteredVehicles = filteredVehicles.filter(v => !v.activated)
      }

      if (this.filterPeriod) {
        filteredVehicles = filteredVehicles.filter(
          v => v.billingPeriod === this.filterPeriod.key
        )
      }

      if (this.sortBy.key === 'vinNumber') {
        filteredVehicles.sort((x, y) => {
          return x.vin < y.vin ? -1 : x.vin > y.vin ? 1 : 0
        })
      } else if (this.sortBy.key === 'unitNumber') {
        filteredVehicles.sort((x, y) => {
          if (x.unitNumber < y.unitNumber) return -1
          if (x.unitNumber > y.unitNumber) return 1
          return 0
        })
      } else if (this.sortBy.key === 'subscriptionEndDate') {
        filteredVehicles.sort((x, y) => {
          if (x.subscriptionEndDate < y.subscriptionEndDate) return -1
          if (x.subscriptionEndDate > y.subscriptionEndDate) return 1
          return 0
        })
      } else if (this.sortBy.key === 'type') {
        filteredVehicles.sort((x, y) => {
          if (x.type < y.type) return -1
          if (x.type > y.type) return 1
          return 0
        })
      }
      return filteredVehicles
    },
    itemsForCart() {
      const array = []
      this.selectedForCart.forEach(selectedTruck => {
        const pricingModel = this.productPrices.find(
          pricingInfo =>
            pricingInfo.servicePeriod == selectedTruck.type &&
            pricingInfo.ratePlanType ==
              roleRatePlanService.userRatePlan(localStorage.getItem('role'))
        )
        const currentRatePlan = selectedTruck.ratePlans.find(
          x => x.productRatePlanId == pricingModel.productRatePlanId
        )
        const servicePrice = pricingModel.servicePrice.find(
          pricing => pricing.currency === this.currency.code
        )
        const cartItem = {
          subscriptionNumber: selectedTruck.subscriptionNumber,
          productRateplanID: pricingModel.productRatePlanId,
          rateplanID: currentRatePlan ? currentRatePlan.id : '',
          productRateplanChargeID: pricingModel.prodRatePlanChargeId,
          type: selectedTruck.type
        }

        if (pricingModel.servicePeriod == 'Annual')
          cartItem.yearlyCost = servicePrice.price
        else if (pricingModel.servicePeriod == 'Month')
          cartItem.monthlyCost = servicePrice.price

        array.push(cartItem)
      })

      return array
    },

    items() {
      //DEPRECATED IN FAVOR OR itemsForCart
      const items = this.selected
      const array = []
      items.forEach(i => {
        this.trucks.forEach(tr => {
          if (tr.subscriptionID === i.subscriptionID) {
            if (tr.type === 'Month') {
              tr.totalCost &&
                tr.totalCost.forEach(cost => {
                  if (cost.servicePeriod === 'Monthly') {
                    cost.servicePrice.forEach(sp => {
                      if (sp.currency === this.currency.code) {
                        array.push({
                          subscriptionNumber: tr.subscriptionNumber,
                          productRateplanID: cost.productRatePlanId,
                          rateplanID: tr.ratePlanID,
                          productRateplanChargeID: cost.prodRatePlanChargeId,
                          type: tr.type,
                          monthlyCost: sp.price
                        })
                      }
                    })
                  }
                })
            } else if (tr.type === 'Annual') {
              tr.totalCost &&
                tr.totalCost.forEach(cost => {
                  if (cost.servicePeriod === 'Annual') {
                    cost.servicePrice.forEach(sp => {
                      if (sp.currency === this.currency.code) {
                        array.push({
                          subscriptionNumber: tr.subscriptionNumber,
                          productRateplanID: cost.productRatePlanId,
                          rateplanID: tr.ratePlanID,
                          productRateplanChargeID: cost.prodRatePlanChargeId,
                          type: tr.type,
                          yearlyCost: sp.price
                        })
                      }
                    })
                  }
                })
            } else if (tr.type === 'Three_Years') {
              tr.totalCost &&
                tr.totalCost.forEach(cost => {
                  if (cost.servicePeriod === 'Three Years') {
                    cost.servicePrice.forEach(sp => {
                      if (sp.currency === this.currency.code) {
                        array.push({
                          subscriptionNumber: tr.subscriptionNumber,
                          productRateplanID: cost.productRatePlanId,
                          rateplanID: tr.ratePlanID,
                          productRateplanChargeID: cost.prodRatePlanChargeId,
                          type: tr.type,
                          yearlyCost: sp.price
                        })
                      }
                    })
                  }
                })
            } else {
              tr.totalCost &&
                tr.totalCost.forEach(cost => {
                  if (cost.servicePeriod === 'Monthly') {
                    cost.servicePrice.forEach(sp => {
                      if (sp.currency === this.currency.code) {
                        array.push({
                          subscriptionNumber: tr.subscriptionNumber,
                          productRateplanID: cost.productRatePlanId,
                          rateplanID: tr.ratePlanID,
                          productRateplanChargeID: cost.prodRatePlanChargeId,
                          type: 'Month',
                          monthlyCost: sp.price
                        })
                      }
                    })
                  } else if (cost.servicePeriod === 'Annual') {
                    cost.servicePrice.forEach(sp => {
                      if (sp.currency === this.currency.code) {
                        array.push({
                          subscriptionNumber: tr.subscriptionNumber,
                          productRateplanID: cost.productRatePlanId,
                          rateplanID: tr.ratePlanID,
                          productRateplanChargeID: cost.prodRatePlanChargeId,
                          type: 'Annual',
                          yearlyCost: sp.price
                        })
                      }
                    })
                  }
                })
            }
          }
        })
      })

      // console.log('items array', array)
      return array
    },
    visibleItemsPerPage() {
      return this.filteredList
      // return this.filteredList.slice(
      //   (this.page - 1) * this.itemsPerPage,
      //   this.page * this.itemsPerPage
      // )
    },
    vehicleInCart() {
      const array = []
      const x =
        this.checkout && this.checkout.products
          ? this.checkout.products.filter(x => x.productID == this.productID)
          : false
      if (x && x.length > 0) {
        x.forEach(product => {
          product.rateplans.forEach(rp => array.push(...rp.subscriptions))
        })
      }
      // console.log('vehicles in cart', array)
      return array
    }
  },
  async created() {
    this.productID = this.$router.history.current.params.id
    await this.getVehicles()
    // console.log(this.service)
  },

  methods: {
    ...mapMutations('checkout', ['addTotalCheckout', 'setCheckout']),
    ...mapActions('notification', ['setSnackbar']),
    // ...mapState('account', [
    //   'currency',
    //   'accountDetails',
    //   'accountInformation',
    //   'payload'
    // ]),

    // userRatePlan(role) {
    //   if (role.includes('Customer Administrator')) {
    //     console.log('CUSTOMER', role)
    //     return 'Customer'
    //   } else if (role.includes('OEM')) {
    //     console.log('POLICY', role)
    //     return 'Policy'
    //   } else if (role.includes('Dealer')) {
    //     console.log('DEALER', role)
    //     return 'Dealer'
    //   }
    //   console.log('UNKNOWN RATEPLAN')
    //   return 'unknown rate plan'
    // },
    getVehicles() {
      this.loading = true
      return apiCalls
        .get(
          api.TrucksPerServiceDetail,
          '',
          `?pageIndex=1&productId=${this.productID}`
        )
        .then(res => {
          this.loading = false
          if (res.status == 200) {
            this.serviceInfo = res.data
            this.trucks = res.data
            this.trucks.forEach(vehicle => (vehicle.selected = false))
          } else {
            this.serviceInfo = []
            this.trucks = []
          }
        })
    },

    evaluateToSunscreen(item) {
      if (this.disabledVehicleInCart(item.subscriptionNumber)) {
        return 'disabledRow'
      }
    },
    selectAllByPeriod() {
      this.selectedBy = 'Yearly'
      this.selected = this.filteredList.filter(
        item =>
          item.billingPeriod === 'Yearly' &&
          !this.vehicleInCart.some(
            cartItem => cartItem.subscriptionID === item.subscriptionNumber
          ) &&
          // * Can't select if the type is 'Three_Years'
          item.type != 'Three_Years' &&
          // * Check if the service is a 'Base Product' and if it's trying to remove (filterStatus = active)
          // * If it is, checks if it doesn't have an AddOn Product before selecting for removal
          // * If it isn't checks if the vehicle already has a 'Base Product' before selecting for subscribing
          (((this.filterStatus.key === 'Unsubscribe Trucks' ||
            this.filterStatus.key === 'Transfer Trucks') &&
            (this.service.category != 'Base Products' ||
              !item.hasAddOnProduct)) ||
            (this.filterStatus.key == 'Subscribe Trucks' &&
              (this.service.category == 'Base Products' ||
                item.hasBaseProduct)))
      )
      this.activeBtn.annual = this.selected.length ? true : false
      this.checkSelected = this.selected.length ? true : false
    },
    setSelectedTrucks({ items: pageItems, value: itemsAreSelected }) {
      if (itemsAreSelected) {
        const vehiclesNotInCart = pageItems.filter(
          vehicle =>
            !this.vehicleInCart.some(
              cartItem => cartItem.subscriptionID === vehicle.subscriptionNumber
            )
        )

        this.selectedForCart = vehiclesNotInCart
      } else {
        this.selectedForCart = []
      }

      pageItems.forEach(vehicle => (vehicle.selected = itemsAreSelected))

      this.activeBtn.annual = this.selectedForCart.length ? true : false
    },
    onTablePageUpdate() {
      this.selected = []
      this.selectedForCart = []
      this.activeBtn.annual = false
    },
    onTableRowCountUpdate(itemsPerPage) {
      this.selected.splice(itemsPerPage)
      this.selectedForCart.splice(itemsPerPage)
    },

    setSelectedTruck(vehicle) {
      if (this.selected.includes(vehicle)) {
        const index = this.selected.indexOf(vehicle)
        if (index > -1) {
          this.selected.splice(index, 1)
          this.selectedForCart.splice(index, 1)
        }
        this.visibleItemsPerPage.forEach(item => {
          if (item.subscriptionNumber == vehicle.subscriptionNumber) {
            item.selected = false
          }
        })
      } else if (
        !this.vehicleInCart.some(
          cartItem => cartItem.subscriptionID === vehicle.subscriptionNumber
        )
      ) {
        this.selected.push(vehicle)
        this.selectedForCart.push(vehicle)
        this.visibleItemsPerPage.forEach(vehicle => {
          if (this.selected.includes(vehicle)) {
            vehicle.selected = true
          }
        })
      }
      this.activeBtn.annual = this.selectedForCart.length ? true : false
    },
    clearSelection() {
      this.filteredList.forEach(vehicle => {
        vehicle.selected = false
      })
      this.selected = []
      this.selectedForCart = []
      this.activeBtn.annual = false
    },
    filterByStatus(status) {
      this.clearSelection()
      this.page = 1
      this.visibleItemsPerPage.forEach(vehicle => {
        vehicle.selected = false
      })
      this.checkSelected = true
      this.filterStatus = status
      this.filterPeriod = null
    },

    filterByPeriod(period) {
      if (this.selectedBy !== period.key) {
        this.clearSelection()
        this.checkSelected = false
      }
      this.filterPeriod = period
    },

    textToSearchReceived(search) {
      this.page = 1
      return (this.textToSearch = search)
    },
    sortByReceived(sortBy) {
      this.page = 1
      return (this.sortBy = sortBy)
    },
    viewVehicleDetail(subscriptionNumber, productId) {
      this.$router.push({
        name: 'vehicle_details',
        params: { id: subscriptionNumber, productId }
      })
    },
    disabledVehicleInCart(subscriptionNumber) {
      return this.vehicleInCart.some(
        x => x.subscriptionID == subscriptionNumber
      )
    },
    bulkStopService() {
      const itemsToAdd = []
      if (
        this.$store.state.checkout.totalCheckout + this.itemsForCart.length >
        this.addToCartLimit
      ) {
        this.setSnackbar({
          showing: true,
          type: 'error',
          text: `Sorry! You can add up to ${this.addToCartLimit} subscription actions to your Cart at this time.`
        })
      } else {
        this.itemsForCart.forEach(itemForCart => {
          itemsToAdd.push({
            subscriptionID: itemForCart.subscriptionNumber,
            productRateplanID: itemForCart.productRateplanID,
            rateplanID: itemForCart.rateplanID,
            productRateplanChargeID: itemForCart.productRateplanChargeID,
            productID: this.productID,
            addSubscription: 0,
            metaData: { '': '' }
          })
        })
        basketService.sendToCheckout(itemsToAdd).then(result => {
          if (result.success) {
            this.setSnackbar({
              showing: true,
              type: 'success',
              timeout: 1500,
              position: 'top',
              center: 'center',
              title: 'Success',
              icon: 'mdi-check-circle',
              text: this.$t('service_details.txt.notification_addcart')
            })
            this.clearSelection()
          } else {
            this.setSnackbar({
              showing: true,
              type: 'error',
              text: this.$t('service_details.txt.notification_error')
            })
          }
        })
      }
    },
    bulkActiveService() {
      if (
        this.$store.state.checkout.totalCheckout + this.itemsForCart.length >
        this.addToCartLimit
      ) {
        this.setSnackbar({
          showing: true,
          title: 'Warning',
          icon: 'mdi-alert',
          position: 'top',
          right: 'right',
          timeout: 7500,
          type: 'warning',
          text: `Sorry! You can add up to ${this.addToCartLimit} subscriptions to your cart at this time.`
        })
      } else {
        const chosenPeriodType =
          this.activeBtn.annual == true
            ? 'Annual'
            : this.activeBtn.month == true
            ? 'Month'
            : ''

        const itemsToAdd = []
        this.itemsForCart.forEach(itemForCart => {
          if (itemForCart.type === chosenPeriodType) {
            itemsToAdd.push({
              subscriptionID: itemForCart.subscriptionNumber,
              productRateplanID: itemForCart.productRateplanID,
              rateplanID: '',
              productRateplanChargeID: itemForCart.productRateplanChargeID,
              productID: this.productID,
              addSubscription: 1,
              metaData: { '': '' }
            })
          }
        })

        basketService.sendToCheckout(itemsToAdd).then(result => {
          if (result.success) {
            this.setSnackbar({
              showing: true,
              type: 'success',
              timeout: 2000,
              position: 'top',
              center: 'center',
              title: 'Success',
              icon: 'mdi-check-circle',
              text: this.$t('service_details.txt.notification_addcart')
            })
            this.activeBtn.annual = false
            this.clearSelection()
          } else {
            this.setSnackbar({
              showing: true,
              title: 'Error',
              icon: 'mdi-alert-circle',
              type: 'error',
              timeout: 7500,
              text: this.$t('service_details.txt.notification_error')
            })
          }
        })
      }

      // if (this.activeBtn.annual == true) {
      // const itemsToAdd = []
      // this.items.forEach(item => {
      //   if (item.type === 'Annual') {
      //     const itemToAdd = {
      //       subscriptionID: item.subscriptionNumber,
      //       productRateplanID: item.productRateplanID,
      //       rateplanID: '',
      //       productRateplanChargeID: item.productRateplanChargeID,
      //       productID: this.productID,
      //       addSubscription: 1,
      //       metaData: { '': '' }
      //     }
      //     itemsToAdd.push(itemToAdd)
      //   }
      // })
      // apiCalls.put(api.CartBulkAdd, '', '', itemsToAdd).then(res => {
      //   if (res.status == 200) {
      //     this.setCheckout(res.data.products)
      //     this.addTotalCheckout(this.selected.length)
      //     this.setSnackbar({
      //       showing: true,
      //       type: 'success',
      //       text: this.$t('service_details.txt.notification_addcart')
      //     })
      //     this.selected = []
      //     this.activeBtn.annual = false
      //   } else {
      //     this.setSnackbar({
      //       showing: true,
      //       type: 'error',
      //       text: this.$t('service_details.txt.notification_error')
      //     })
      //   }
      // })
      // } else if (this.activeBtn.month == true) {
      // const itemsToAdd = []
      // this.items.forEach(item => {
      //   if (item.type === 'Month') {
      //     const itemToAdd = {
      //       subscriptionID: item.subscriptionNumber,
      //       productRateplanID: item.productRateplanID,
      //       rateplanID: '',
      //       productRateplanChargeID: item.productRateplanChargeID,
      //       productID: this.productID,
      //       addSubscription: 1,
      //       metaData: { '': '' }
      //     }
      //     itemsToAdd.push(itemToAdd)
      //   }
      // })
      // apiCalls.put(api.CartBulkAdd, '', '', itemsToAdd).then(res => {
      //   if (res.status == 200) {
      //     this.setCheckout(res.data.products)
      //     this.addTotalCheckout(this.selected.length)
      //     this.setSnackbar({
      //       showing: true,
      //       type: 'success',
      //       text: this.$t('service_details.txt.notification_addcart')
      //     })
      //     this.selected = []
      //     this.activeBtn.month = false
      //   } else {
      //     this.setSnackbar({
      //       showing: true,
      //       type: 'error',
      //       text: this.$t('service_details.txt.notification_error')
      //     })
      //   }
      // })
      // }
    },

    bulkTransferOwnerService() {
      const itemsToAdd = []
      this.itemsForCart.forEach(itemForCart => {
        if (itemForCart.type === 'Annual') {
          itemsToAdd.push({
            subscriptionID: itemForCart.subscriptionNumber,
            productRateplanID: itemForCart.productRateplanID,
            rateplanID: '',
            productRateplanChargeID: itemForCart.productRateplanChargeID,
            productID: 'changeOwner',
            addSubscription: 1,
            metaData: ''
          })
        }
      })

      basketService.sendToCheckout(itemsToAdd).then(result => {
        if (result.success) {
          this.setSnackbar({
            showing: true,
            type: 'success',
            text: this.$t('service_details.txt.notification_addcart')
          })
          this.clearSelection()
          this.activeBtn.annual = false
        } else {
          this.setSnackbar({
            showing: true,
            type: 'error',
            text: this.$t('service_details.txt.notification_error')
          })
        }
      })
    },
    onActiveButtonAnnualClicked() {
      this.activeBtn.annual = !this.activeBtn.annual
    },
    onActiveButtonMonthClicked() {
      this.activeBtn.month = !this.activeBtn.month
    }
  }
}
</script>

<style lang="scss">
.service-detail-list {
  .page-title {
    background-color: var(--v-primary-darken1);
  }
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  h1 {
    margin-bottom: 22px;
    font-size: 3rem;
    font-weight: 300;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: left;
  }
  .no-results {
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    color: var(--v-bluegrey-base);
    .v-icon {
      font-size: 100px;
      color: var(--v-cloudy-base);
    }
  }
  .results {
    &_table {
      margin-bottom: 32px;
      .header {
        padding: 0 12px;
        [class*='col-'] {
          text-transform: uppercase;
          font-size: 0.8125rem;
          color: var(--v-bluegrey-base);
          padding: 10px;
        }
        .check-item {
          .v-input--selection-controls__input {
            width: 16px;
            border-radius: 4px;
            background-color: var(--v-pale-three-base);

            i {
              color: var(--v-pale-three-base);
              caret-color: var(--v-pale-three-base);
            }
          }

          &.v-input--is-disabled {
            .v-input--selection-controls__input {
              opacity: 0.6;
              i {
                &:before {
                  content: '';
                }
              }
            }
          }
        }
      }
      .body {
        .item {
          .v-card {
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
            color: var(--v-slate-base);

            &.selected {
              background-color: var(--v-peacock-base);
              color: white;
              .v-input--selection-controls__input {
                background-color: var(--v-peacock-base) !important;
                i {
                  color: var(--v-pale-three-base) !important;
                }
              }
              .vehicle-name {
                color: white !important;
              }
            }

            .row {
              height: 54px;

              div[class*='col-'] {
                font-size: 0.875rem;
                .vehicle-name {
                  cursor: pointer;
                  color: black;
                  letter-spacing: 0.1em;
                  font-weight: bold;
                }

                .check-item {
                  .v-input__slot {
                    margin: 0;
                    .v-input--selection-controls__input {
                      width: 16px;
                      height: 16px;
                      border-radius: 4px;
                      background-color: var(--v-pale-three-base);

                      i {
                        color: var(--v-pale-three-base);
                      }
                    }
                  }
                }
                .show-button {
                  font-size: 0.75rem;
                  font-weight: bold;
                  text-align: center;
                  color: var(--v-peacock-base);
                  height: 32px;
                  border-radius: 3px;
                  border: solid 1px rgba(0, 92, 169, 0.3);
                  background-color: white;
                }
              }
            }
          }
        }
      }
    }
    &_pagination {
      .v-pagination {
        &__item {
          font-weight: bold;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          margin: 0 8px;
          &:focus {
            outline: none !important;
          }
          &--active {
            color: var(--v-darkgrey-blue-base) !important;
            box-shadow: none;
          }
        }
        &__navigation {
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          margin: 0 40px;
        }
        .v-icon {
          font-size: 1.25rem;
          color: var(--v-slate-base);
        }
      }
    }
    &_footer {
      &_btn {
        width: 100%;
        height: 48px !important;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        &.proceed {
          background-image: linear-gradient(to bottom, #266cab 0%, #01529c);
          color: white;
        }
        &.cancel {
          background-image: linear-gradient(
            to bottom,
            #c53030 0%,
            #9b2c2c 100%
          );
          color: white;
        }
        &.transfer {
          background-image: linear-gradient(
            to bottom,
            #43a052 0%,
            #128025 100%
          );
          color: white;
        }
        &.disabled {
          background-color: var(--v-cloudy-base) !important;
          color: white !important;
        }
      }
      &_costs {
        font-size: 1.25rem;
        color: var(--v-slate-base);
      }
      &_chooseperiod {
        .label {
          font-size: 0.75rem;
          font-weight: bold;
          color: var(--v-peacock-base);
          text-transform: uppercase;
        }
        .buttons {
          .v-btn {
            width: 128px;
            height: 32px;
            border-radius: 3px;
            border: solid 1px var(--v-cloudy-base);
            background-color: white !important;
            font-size: 0.75rem;
            text-align: center;
            color: var(--v-steel-base);
            span {
              text-transform: lowercase;
            }
            &.v-btn--active {
              background-color: var(--v-peacock-base) !important;
              color: white !important;
            }

            &.disabled {
              color: var(--v-steel-base);
            }
          }
        }
      }
    }
  }
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-messages {
  min-height: 0px;
}

.vehicle-name-tooltip {
  min-height: 32px !important;
  border-radius: 16px !important;
  span {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
  }
}
.vehicle-name {
  letter-spacing: 0.12rem;
  font-weight: bold;
}
.show-button {
  font-size: 0.75rem !important;
  font-weight: bold;
  text-align: center;
  color: var(--v-peacock-base) !important;
  height: 32px;
  border-radius: 3px;
  border: solid 1px rgba(0, 92, 169, 0.3);
  background-color: white !important;
}

.v-data-table__selected:not(.disabledRow) {
  background-color: var(--v-peacock-base) !important;
  color: white !important;
  .v-input--selection-controls__input {
    background-color: var(--v-peacock-base) !important;
    i {
      color: var(--v-pale-three-base) !important;
    }
  }
}

.buttonVisibility {
  visibility: 'hidden';
  opacity: 0;
  transition: none;
}
.disabledRow {
  position: relative;

  td {
    opacity: 0.2;
  }
  &::after {
    content: '\F15EA';
    font-size: 100px;
    background-color: #c7bfb521;
    position: absolute;
    top: 0;
    color: black;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 38px/1 'Material Design Icons';
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
</style>