import Vue from 'vue'
import Vuex from 'vuex'
import parseJwt from '@/helpers/parseJwt'

Vue.use(Vuex)

const state = {
  userToken: localStorage.getItem('userToken') || null,
  accessToken: null,
  currency: { symbol: '$', code: 'USD' },
  accountDetails: {},
  payload: {},
  accountInformation: {},
  appContext: {},
  appContextWorkingAccount: { name: '', number: '' },
  roleDetails: {
    role: '',
    trimbleEntityKey: '',
    hasWebShopAccess: false,
    hasOEMOrDealerAccess: false,
    hasAccountonZuora: false
  }
}

const getters = {
  isAuthenticated: state => state.userToken !== null
}

const mutations = {
  setCurrency(state, n) {
    state.currency = n
  },
  setAccountDetails(state, n) {
    state.accountDetails = n
  },
  setPayload(state, payload) {
    state.payload = payload
  },
  setAccountInformation(state, accountInfo) {
    state.accountInformation = accountInfo
  },
  setUserToken(state, token) {
    state.userToken = token
  },
  setAccessToken(state, token) {
    state.accessToken = token
  },
  setAppContext(state, token) {
    state.appContext = token
  },
  setAppToken(state, token) {
    state.detailsToken = token
  },
  setAppContextWorkingAccount(state, workCtx) {
    state.appContextWorkingAccount.name = workCtx.name
    state.appContextWorkingAccount.number = workCtx.number
  },
  setRoleDetails(state, roleDetails) {
    state.roleDetails = { ...roleDetails }
  },
  setAccountOnZuora(state, hasAccountonZuora) {
    state.roleDetails.hasAccountonZuora = hasAccountonZuora
  }
}

const actions = {
  async getCurrencyInfo() {
    // async getCurrencyInfo({ commit }) {
    // return await apiCalls.get(api.Currencies, '', '').then(res => {
    //   const tt = res.data.filter(
    //     item => item.code === state.accountInformation.currency
    //   )
    //   commit('setCurrency', tt[0])
    // })
  },
  async getAccountDetails() {
    // #Paccar-POC
    // console.log('THIS IS FROM THE ACCOUNT.JS')
    // return await apiCalls
    //   .get(
    //     api.AccountDetails,
    //     localStorage.getItem('customerAdminAccount'),
    //     'details'
    //   )
    //   .then(res => {
    //     commit('setAccountDetails', res.data.userInfo)
    //     commit('setAccountInformation', res.data.companyInfo)
    //     // commit('setCurrency', res.data.currency)
    //   })
  },
  async getPayload({ commit }, token) {
    const payload = parseJwt(token)
    commit('setPayload', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
